import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { PiEyeLight, PiEyeSlashLight } from "react-icons/pi";
import useAuthHooks from "../../Hooks/authHooks";
import { Button } from "../../components/Button";
import { routeObj } from "../../constants/routes";
import { FormInput } from "../../components/Input";
import Header from "../../Layout/component/Header";

export default function SignIn() {
  const { login } = useAuthHooks();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="relative">
      <div className="absolute top-2 left-2 z-10 md:text-xl bg-red-00 text-main">
        <img
          onClick={() => navigate("/")}
          src="/images/logo.png"
          alt=""
          className=" w-[100px] md:w-[150px]  "
        />
      </div>
      <div className="flex  text-black">
        <div
          className="h-[100vh] relative hidden md:flex bg-[98%] w-[40%]  justify-center items-center  bg-no-repeat b py- px-5 bg-cover  "
          style={{ backgroundImage: `url('/images/login.png')` }}
        >
          <img
            onClick={() => navigate("/")}
            src="/images/login1.png"
            alt=""
            className=" absolute bottom-0 left-0 "
          />
        </div>
        <div className=" relative flex h-[100vh] w-full md:w-[100%] bg-[#EDF4FF py-20 md:py-0 justify-center md:items-center ">
          <div className=" md:hidden absolute top-5 left-4 "></div>
          <div className=" h-fit bg-red-00 w-[90%] md:w-3/4  ">
            <div className=" flex items-center flex-col md:flex-row justify-between pb-12 md:pb-0 ">
              <div className=" md:w-full  md:text-center  md:text-[24px] font-bold py-4">
                Login
              </div>
              <div className=" whitespace-nowrap text-xs md:text-sm ">
                Not registered?{" "}
                <Link to={routeObj.register}>
                  <span className=" font-bold text-primary ">Sign Up</span>
                </Link>
              </div>
            </div>
            <form onSubmit={login.handleSubmit}>
              <FormInput
                label="Email"
                className="rounded-lg  bg-[#EDF4FF"
                name="email"
                error={login.errors.email}
                value={login.values.email}
                onBlur={login.handleBlur}
                onChange={login.handleChange}
              />
              <FormInput
                label="Password"
                className="rounded-lg  bg-[#EDF4FF"
                name="password"
                type={showPassword ? "text" : "password"}
                icon={showPassword ? PiEyeLight : PiEyeSlashLight}
                error={login.errors.password}
                value={login.values.password}
                onBlur={login.handleBlur}
                onChange={login.handleChange}
                marginBottom={false}
                onClick={() => {
                  {
                    showPassword
                      ? setShowPassword(false)
                      : setShowPassword(true);
                  }
                }}
              />
              <Link
                to={routeObj.forgot}
                className="text-right py-3 block text-sm md:text-[15px] text-primary  "
              >
                Forgot Password
              </Link>
              <Button
                type="submit"
                className=" bg-primary block m-auto w-full md:w-fit  text-white px-16 py-3 font-bold "
                loading={login.isSubmitting}
              >
                Login
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
