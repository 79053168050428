import { motion } from "framer-motion";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import { authConfig } from "../../../Utils/constants";

export default function AboutAccess() {
  return (
    <div className=" flex flex-col md:flex-row bg-blue-00 items-center -mt-[50px] md:-mt-[100px] py-10 md:py-20  ">
      <div className=" md:w-[50%] md:p-10 ">
        <h3 className=" font-bold text-center md:w-[80%] capitalize md:text-left text-primary ">
          We seek to grow and safeguard the investments of all our clients
        </h3>

        <p className=" md:w-[80%] font-light py-5 text-[12px] md:text-[16px] text-center md:text-left  ">
          Consistent profits will be generated as long as there's volatility in
          Bitcoin and other cryptocurrencies prices. It does not matter if you
          have extensive experience in the markets or are new to it, Quotrack
          Chain will provide you with insightful information, analysis and
          judgments on the current markets. The mission of our company is to
          provide a reliable and profitable management system for all members.
          We seek to grow and safeguard the investments of all our clients in
          our blockchain system for maximum security and trust.The whole
          investment system is built on state of art quantitative models.
        </p>
        <motion.button
          whileHover={{ scale: 1.05 }}
          className=" mt-7 hidden md:flex items-center gap-3  bg-primary py-3 px-5 mb-10 md:mb-0 rounded-lg text-white  font-bold mx-auto md:mx-0  "
        >
          Learn More <BsArrowRight />
        </motion.button>
      </div>
      <div className=" hidden md:block mt-10 md:mt-0 ">
        <img src="/images/about.png" loading="lazy" alt="" />
        <motion.button
          whileHover={{ scale: 1.05 }}
          className=" mt-8 flex md:hidden text-sm items-center gap-3  bg-primary py-3 px-5 mb-10 md:mb-0 rounded-lg text-white font-bold mx-auto md:mx-0  "
        >
          Learn More <BsArrowRight />
        </motion.button>
      </div>
    </div>
  );
}
