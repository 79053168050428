import React from "react";
import LayoutOne from "../../Layout/Layout_One";
import { Button } from "../../components/Button";

export default function Security() {
  return (
    <LayoutOne shadow={true}>
      <div className=" mt- ">
        <div className=" bg-[#F7F9FB] px-5 md:px-20 py-10 flex items-center  gap-10 md:gap-0 flex-col md:flex-row ">
          <div className=" text-center md:text-left md:w-[50%] md:px-5 ">
            <h1 className=" font-bold md:w-[80%] text-xl md:text-3xl ">
              Do not be a victim of Online scams{" "}
            </h1>
            <p className=" md:w-[80%] py-4 text-[13px] md:text-lg ">
              In today’s digital age, online scams have become increasingly
              sophisticated, posing significant risks to individuals and their
              personal and financial information. As technology advances, so do
              the tactics employed by cybercriminals, making it more challenging
              to identify and avoid these malicious schemes. From phishing
              emails that appear alarmingly authentic to fake websites designed
              to steal your sensitive data, the landscape of online scams is
              constantly evolving.
            </p>
            {/* <Button className=" my-0 bg-primary text-white px-6 py-3 ">
              Read More
            </Button> */}
          </div>
          <div className=" md:w-[50%] ">
            <img
              src="/images/scam.png"
              className=" rounded-lg md:rounded-non "
              alt=""
            />
          </div>
        </div>
        <div className="flex bg-[#FFFFFF] py-10 md:py-20 justify-center px-8 gap-6 md:gap-10 md:px-20 py items-center flex-col ">
          <div className="">
            <p className=" md:text-3xl font-bold ">All you need to know</p>
            <div className="w-[50px] md:w-[100px] my-1 h-1 bg-black "></div>
          </div>
          <div className="text-[13px] md:text-lg flex flex-col gap-4 ">
            <p>
              Cybercrime is surging, and no one is immune. Contrary to popular
              belief, millennials are now more likely than seniors to fall
              victim to online fraud, according to recent FTC data. In 2020,
              California alone reported over 3,500 identity theft cases.
            </p>

            <p>
              The internet's convenience comes with risks. Scammers are
              evolving, targeting all age groups with sophisticated tactics.
              Your hard-earned money is at stake. Stay vigilant.
            </p>
            <p>
              Educate yourself. Protect your digital identity. Don't become
              another statistic in the growing epidemic of online fraud.
            </p>
          </div>
        </div>
        <div
          className=" bg-center bg-contain flex items-center justify-center h-[200px] md:h-[300px] bg-primary  bg-no-repeat "
          //   style={{ backgroundImage: `url('/images/priority.png')` }}
        >
          <p className=" text-center text-white font-bold px-4 text-xl animate-pulse md:text-3xl ">
            At Quotrack Your Security <br /> Is Our Top Priority
          </p>
        </div>

        <div className="flex bg-[#FFFFFF] py-20 justify-center px-4 gap-10 md:px-20 py items-center flex-col ">
          <div className="">
            <p className=" md:text-3xl text-center md:text-left font-bold ">
              Online scams you should Watch out for
            </p>
            <div className=" hidden md:block md:w-[100px] my-1 h-1 bg-black "></div>
          </div>
          <div className="flex flex-col md:flex-row pt-0 justify-between items-center gap-4 ">
            <div className=" md:w-[45%] ">
              <h3 className=" font-bold md:text-2xl ">Facebook Scams</h3>
              <p className=" text-[13px] md:text-lg pt-3 md:pt-5 ">
                Beware of 'friends' offering free money on Facebook. The Better
                Business Bureau warns these are scammers using fake or hacked
                profiles. The trap? You're asked to pay fees or share sensitive
                information upfront. Don't fall for it—there's no free money,
                only identity theft risks
              </p>
              <p className=" text-primary text-sm md:text-[16px] font-bold py-3 md:py-5 ">
                So do not:
              </p>
              <div className="flex flex-col gap-5 ">
                <div className="flex items-center gap-3">
                  <div className=" h-2 md:h-3  w-2 md:w-3 text-xs rounded-full bg-primary "></div>
                  <p className=" text-[13px] md:text-lg ">
                    Give out your password
                  </p>
                </div>
                <div className="flex items-center gap-3">
                  <div className=" h-2 md:h-3  w-2 md:w-3 text-xs rounded-full bg-primary "></div>
                  <p className="text-[13px] md:text-lg ">
                    Use a public WIFI when signing in{" "}
                  </p>
                </div>
                <div className="flex items-center gap-3">
                  <div className=" h-2 md:h-3  w-2 md:w-3 text-xs rounded-full bg-primary "></div>
                  <p className="text-[13px] md:text-lg ">
                    {" "}
                    Accept unknown friend requests
                  </p>
                </div>
                <div className="flex items-center gap-3">
                  <div className=" h-2 md:h-3  w-2 md:w-3 text-xs rounded-full bg-primary "></div>
                  <p className="text-[13px] md:text-lg ">
                    Use outdated antivirus software, browsers or apps
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="w-[45%] min-h-fit max-h-[400px] bg-[80%] bg-cover overflow-hidden "> */}
            <div className=" md:w-[45%]  ">
              <img
                src="/images/faceb.png"
                className="object-center  object-fill"
                alt=""
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row pt-0 justify-between items-center gap-4 ">
            <div className=" md:w-[45%] ">
              <h3 className=" font-bold md:text-2xl ">Scam websites</h3>
              <p className=" pt-5 text-[13px] md:text-[16px]">
                Beware of too-good-to-be-true deals on unfamiliar websites.
                Scammers are creating fake shops to steal your money. Shop
                smart, stick to trusted sites, and protect your wallet from
                digital deceit.
              </p>
              <p className=" text-primary font-bold text-sm md:text-[16px] py-5 ">
                So always:
              </p>
              <div className="flex flex-col gap-5 ">
                <div className="flex items-center gap-3">
                  <div className=" h-2 md:h-3  w-2 md:w-3 text rounded-full bg-primary "></div>
                  <p className=" text-[13px] md:text-[16px] ">
                    Verify suspicious sites with Google's Transparency tool or
                    BBB's Scam Tracker{" "}
                  </p>
                </div>
                <div className="flex items-center gap-3">
                  <div className=" h-2 md:h-3  w-2 md:w-3 text rounded-full bg-primary "></div>
                  <p className="text-[13px] md:text-[16px]">
                    Shop only on secure networks, never public Wi-Fi{" "}
                  </p>
                </div>
                <div className="flex items-center gap-3">
                  <div className=" h-2 md:h-3  w-2 md:w-3 text rounded-full bg-primary "></div>
                  <p className="text-[13px] md:text-[16px]">
                    {" "}
                    Ensure the URL starts with 'https' for encrypted
                    transactions{" "}
                  </p>
                </div>
                <div className="flex items-center gap-3">
                  <div className=" h-2 md:h-3  w-2 md:w-3 text rounded-full bg-primary "></div>
                  <p className="text-[13px] md:text-[16px]">
                    Enable two-factor authentication for online payments{" "}
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="w-[45%] min-h-fit max-h-[400px] bg-[80%] bg-cover overflow-hidden "> */}
            <div className="md:w-[45%]  ">
              <img
                src="/images/hacked.png"
                className="object-center  object-fill"
                alt=""
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row pt-0 justify-between items-center gap-4 ">
            <div className=" md:w-[45%] ">
              <h3 className=" font-bold md:text-2xl ">Texting scams</h3>
              <p className="text-[13px] md:text-[16px]  pt-5 ">
                Beware of smishing scams, where fraudsters use urgent or
                enticing text messages to trick you into clicking links or
                sharing personal information, just like phishing emails, and
                remember that legitimate companies won't ask for sensitive data
                via text
              </p>
              <p className=" text-primary text-sm md:text-[16px] font-bold py-5 ">
                So always:
              </p>
              <div className="flex flex-col gap-5 ">
                <div className="flex items-center gap-3">
                  <div className=" h-2 md:h-3  w-2 md:w-3 text rounded-full bg-primary "></div>
                  <p className="text-[13px] md:text-[16px]">
                    Avoid replying and clicking links{" "}
                  </p>
                </div>
                <div className="flex items-center gap-3">
                  <div className=" h-2 md:h-3  w-2 md:w-3 text rounded-full bg-primary "></div>
                  <p className="text-[13px] md:text-[16px]">Delete the text </p>
                </div>
                <div className="flex items-center gap-3">
                  <div className=" h-2 md:h-3  w-2 md:w-3 text rounded-full bg-primary "></div>
                  <p className="text-[13px] md:text-[16px]">
                    Use a secure network{" "}
                  </p>
                </div>
                <div className="flex items-center gap-3">
                  <div className=" h-2 md:h-3  w-2 md:w-3 text rounded-full bg-primary "></div>
                  <p className="text-[13px] md:text-[16px]">
                    {" "}
                    Keep antivirus software, browsers and apps updated{" "}
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="w-[45%] min-h-fit max-h-[400px] bg-[80%] bg-cover overflow-hidden "> */}
            <div className="md:w-[45%]  ">
              <img
                src="/images/fishing.png"
                className="object-center  object-fill"
                alt=""
              />
            </div>
          </div>
        </div>
        <div
          className=" bg-center  md:mb-40 bg-cover flex items-center justify-center h-[200px] w-full md:h-[600px] flex-col bg-primary  bg-no-repeat "
          style={{ backgroundImage: `url('/images/contact.png')` }}
        >
          <div className=" w-[80%] bg-red-00 ">
            <p className=" md:text-5xl text-center px-6 md:px-20 md:leading-[65px] font-bold text-white ">
              Stay safe online. We're here to help. Questions? Contact us.
            </p>
            <div className="w-[100%] md:w-[50%] md:my-6 md:py-4 rounded-md mt-5 pl-7 pr-2 flex items-center justify-between mx-auto bg-white  ">
              <input
                type="text"
                placeholder="Enter Your Questions"
                className=" py-2 placeholder:text-[12px] w-[80%] outline-none "
              />
              <Button className=" my-0 bg-primary text-white text-[12px] md:text-sm  px-5 py-1 ">
                Send
              </Button>
            </div>
          </div>
        </div>
      </div>
    </LayoutOne>
  );
}
