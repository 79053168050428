export type TokenType = {
  access_token: string;
  token_type: string;
  expires_in: number;
};

export type LoginUser = {
  user: UserType;
  auth: TokenType;
};
export type AuthDetailType = {
  user: UserType | null;
  token: string | null;
  check_auth: boolean;
  loading: boolean;
};

export const initialState: AuthDetailType = {
  user: null,
  token: "",
  check_auth: true,
  loading: false,
};
