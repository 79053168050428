import { Button } from "../../components/Button";
import LayoutOne from "../../Layout/Layout_One";
import { inv_types } from "../../Utils/data";
import { SiStackshare } from "react-icons/si";
import { GiArchiveRegister } from "react-icons/gi";
import { PiHandCoinsBold } from "react-icons/pi";
import { BiArrowToRight } from "react-icons/bi";
import { authConfig } from "../../Utils/constants";
import { FaCircleCheck } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { routeObj } from "../../constants/routes";

export default function Packages() {
  return (
    <div className=" py-20 ">
      <div className="">
        <h3 className=" font-bold text-[12px] md:text-[20px] capitalize text-center md:text-center text-primary ">
          Our investment plans
        </h3>
        <h1 className="  text-sm md:text-lg mx-auto w-[90%] text-center font-light md:text-center py-4 ">
          We have the best plans just for you
        </h1>
        <div className=" w-[90%] md:w-[100%] m-auto flex flex-col md:flex-row flex-wrap gap-8 py-10 justify-between ">
          {inv_types?.map((item, index) => (
            <div
              key={index}
              style={{ background: item.bg }}
              className={`w-[90%] mx-auto md:w-[20%]  rounded-lg shadow-md py-5  px-5 flex flex-col gap-6 `}
            >
              <p className="   bg-yellow-00 font- text-l ">{item.plan}</p>

              {item.plan !== "Contract Plan" && (
                <p className=" text-nowrap  bg-yellow-00 font-semibold text-xl ">
                  {item.min} - {item.max}
                </p>
              )}
              {item.plan === "Contract Plan" && (
                <p className=" text-nowrap  bg-yellow-00 font-semibold text-xl ">
                  {item.min} and Above
                </p>
              )}

              <Link to={routeObj.deposit_options}>
                {" "}
                <Button className="  w-full py-3 text-whit bg-[#2C3033] text-white border- flex justify-center items-center gap-3 ">
                  Invest Now
                </Button>
              </Link>

              {/* <p className="   bg-yellow-00 font-light text-sm  ">
                For 3 weeks
              </p> */}

              <div className="flex items-center gap-3 text-sm font-light ">
                <FaCircleCheck /> Weekly Profit: {item.weekly}
              </div>
              <div className="flex items-center gap-3 text-sm font-light  ">
                <FaCircleCheck /> Referral Percentage: {item.ref}
              </div>
              <div className="flex items-center gap-3 text-sm font-light  ">
                <FaCircleCheck /> Capital Insurance: Available
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className="bg-red-00 h-[350px] md:h-[600px]  bg-[15%]  md:bg-center bg-cover bg-no-repeat "
        style={{ backgroundImage: `url('/images/packages.svg')` }}
      >
        <div className=" h-full  overflow-y-scroll">
          <div className="flex items-center bg-red-0 h-full  justify-center py-[20px] w-full  ">
            <div className="w-full flex flex-col justify-center items-center gap-3  pt-0  ">
              <h1 className=" text-[15px] md:text-[30px] text-white text-center ">
                Kick start your investment journey <br /> today with Quotrack
              </h1>
              <Button
                className={` py-3 md:py-4 w-fit px-8  p bg-[#242D2D] text-white text-center `}
              >
                Learn More
              </Button>
            </div>
          </div>{" "}
        </div>
      </div>
    </div>
  );
}
