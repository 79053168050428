export default function Investment() {
  return (
    <div className=" py-6 bg-red-00  ">
      <h3 className=" font-bold text-[12px] md:text-sm text-center md:text-center text-primary ">
        Why choose Qutrack Finance?
      </h3>
      <h1 className=" font-bold capitalize text-sm md:text-xl mx-auto w-[90%] text-center md:text-center py-5 ">
        Join the only publicly-traded firm dedicated to harnessing the potential
        of decentralized finance and blockchain.
      </h1>
      <div className=" m-auto w-[95%] flex-wrap md:flex-row py-4  md:py-8  flex justify-around md:justify-between gap-4 ">
        <div className=" w-[47%] md:w-[30%] shadow-md  border-[1px] border-[#47444433] rounded-lg px-2 md:px-5 py-10 ">
          <div>
            <img
              className="h-[30px] w-[30px]  "
              src="/images/check.svg"
              alt=""
            />
          </div>
          <p className=" font-bold uppercase text-[12px] md:text-lg py-4 ">
            Instant payments{" "}
          </p>
          <p className="  text-[10px] md:text-sm  ">
            Our withdrawals are all processed instantly after they are
            requested.
          </p>
        </div>
        <div className=" w-[47%] md:w-[30%] shadow-md  border-[1px] border-[#47444433] rounded-lg px-2 md:px-5 py-10 ">
          <div>
            <img
              className="h-[30px] w-[30px]  "
              src="/images/check.svg"
              alt=""
            />
          </div>
          <p className=" font-bold uppercase text-[12px] md:text-lg py-4 ">
            Dedicated server{" "}
          </p>
          <p className="  text-[10px] md:text-sm  ">
            We use a dedicated server with the highest level of DDOS protection
            to ensure that your funds are always safe with us.
          </p>
        </div>{" "}
        <div className=" w-[47%] md:w-[30%] shadow-md  border-[1px] border-[#47444433] rounded-lg px-2 md:px-5 py-10 ">
          <div>
            <img
              className="h-[30px] w-[30px]  "
              src="/images/check.svg"
              alt=""
            />
          </div>
          <p className=" font-bold uppercase text-[12px] md:text-lg py-4 ">
            DDOS protection
          </p>
          <p className="  text-[10px] md:text-sm  ">
            We use the highest level of protection. We have applied measures to
            mitigate risk and attacks on our website.
          </p>
        </div>
        <div className=" w-[47%] md:w-[30%] shadow-md  border-[1px] border-[#47444433] rounded-lg px-2 md:px-5 py-10 ">
          <div>
            <img
              className="h-[30px] w-[30px]  "
              src="/images/check.svg"
              alt=""
            />
          </div>
          <p className=" font-bold uppercase text-[12px] md:text-lg py-4 ">
            Solid risk management
          </p>
          <p className="  text-[10px] md:text-sm  ">
            Our risk management protocols include rigorous internal controls and
            limits. All trades are taken with a focus on risk
          </p>
        </div>{" "}
        <div className=" w-[47%] md:w-[30%] shadow-md  border-[1px] border-[#47444433] rounded-lg px-2 md:px-5 py-10 ">
          <div>
            <img
              className="h-[30px] w-[30px]  "
              src="/images/check.svg"
              alt=""
            />
          </div>
          <p className=" font-bold uppercase text-[12px] md:text-lg py-4 ">
            Decentralized system
          </p>
          <p className="  text-[10px] md:text-sm  ">
            Our website is secured with third-level encryptions in a
            decentralized chain of nodes.
          </p>
        </div>{" "}
        <div className=" w-[47%] md:w-[30%] shadow-md  border-[1px] border-[#47444433] rounded-lg px-2 md:px-5 py-10 ">
          <div>
            <img
              className="h-[30px] w-[30px]  "
              src="/images/check.svg"
              alt=""
            />
          </div>
          <p className=" font-bold uppercase text-[12px] md:text-lg py-4 ">
            24/7 support
          </p>
          <p className="  text-[10px] md:text-sm  ">
            Our support is working 7/24 hours online and our online chat support
            is working about 24 hours daily.
          </p>
        </div>
      </div>
    </div>
  );
}
