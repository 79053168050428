export const we_solve = [
  "/images/tax.png",
  "/images/hedge.png",
  "/images/wealth.png",
  "/images/fund.png",
  "/images/retire.png",
  "/images/portfolio2.png",
  "/images/calc.png",
  "/images/settings.png",
];

export const we_solve2 = [
  "/images/tax2.png",
  "/images/hedge2.png",
  "/images/wealth2.png",
  "/images/fund2.png",
  "/images/retire2.png",
  "/images/portfolio.png",
  "/images/calc2.png",
  "/images/settings2.png",
];

export const inv_types = [
  {
    picture: "/images/basic.svg",
    slug: "starter",
    plan: "Starter",
    min: "$500",
    max: "$1,999",
    ref: "5%",
    weekly: "2.5%",
    bg: "#FFBC99",
  },
  {
    picture: "/images/standard.svg",
    slug: "prime",
    plan: "Prime Investor",
    min: "$2,000",
    max: "$49,999",
    ref: "6%",
    weekly: "4%",
    bg: "#52B5B5",
  },
  {
    picture: "/images/premium.svg",
    slug: "pro",
    plan: "Pro Investor",
    min: "$50,000",
    max: "$199,999",
    ref: "7%",
    weekly: "6.5%",
    bg: "#80CF7F",
  },
  {
    picture: "/images/zenith.svg",
    slug: "elite",
    plan: "Elite Investor",
    min: "$200,000",
    max: "$1,000,000",
    ref: "9%",
    weekly: "10%",
    bg: "#2C7CB2",
  },
  {
    picture: "/images/zenith.svg",
    slug: "contract",
    plan: "Contract Plan",
    min: "$1,000,000",
    max: "N/A",
    ref: "N/A",
    weekly: "13.5%",
    bg: "#EEE8A9",
  },
];

export const accordionData = [
  {
    title: "What type of investments do you offer?",
    desc: "We offer a variety of investments including cryptocurrencies, forex, mutual funds, index funds, individual stocks, bonds, and real estate.",
  },
  {
    title: "What is an Investment Platform?",
    desc: "An investment platform is a digital or online service that allows users to manage and execute their investments. It provides tools and resources for buying and selling various types of investments, such as stocks, bonds, mutual funds, and ETFs. Investment platforms often offer features like portfolio management, research and analysis tools, and financial planning resources. They can range from basic brokerage accounts to more advanced platforms with comprehensive investment options and advisory services.",
  },
  {
    title: "What is the minimum amount required to start investing?",
    desc: " The minimum investment amount varies depending on the type of investment package a user is investing on.",
  },

  {
    title: "What is a diversified portfolio and why is it important?",
    desc: "A diversified portfolio includes a mix of different investments to spread risk and reduce volatility. It is important because it helps protect your investments from market fluctuations.",
  },
  {
    title: " How do you handle tax implications for investments?",
    desc: "We provide tax-efficient investment strategies and offer tools to help you understand the tax implications of your investments. It is advisable to consult with a tax professional for personalized advice.",
  },
  {
    title: "Who controls the investment Portfolio?",
    desc: "The investment portfolio is managed by a team of experienced financial specialists, of lawyers, professional trade analysts who have been working on the currency and stock exchange market for more than 10 years on average. Our experience and contacts ensure access for us to a wide range of local and global resources and bring about benefit from the world’s best and most effective technologies of trading on the real estate,oil,Forex, Binary and Cryptocurrency market.",
  },
  {
    title: "Can I automate my investments on your platform?",
    desc: "Yes, we offer automated investment options through features like dollar-cost averaging and automated rebalancing. These tools can help you stay disciplined and manage your portfolio efficiently.",
  },
  {
    title: "Are my investments protected?",
    desc: "Yes, we take the security of your investments seriously. We use industry-standard encryption and security measures to safeguard your account and personal information. Additionally, investments in certain assets may be protected by regulatory agencies.",
  },
  {
    title: "Do you offer any investment incentives or bonuses?",
    desc: " While we do offer bonuses and incentives on special occasions at the discretion of the management, we provide competitive investment opportunities with transparent fees. Our focus is on delivering reliable, long-term returns for our clients",
  },
  {
    title: "What is the duration of the withdrawal mode?",
    desc: "Absolutely! We believe in giving our clients flexibility. You can adjust your investment portfolio by buying or selling assets based on your changing financial goals and risk tolerance.",
  },
  {
    title:
      "Are my investments covered by insurance in case of a market downturn?",
    desc: "Investments are insured against market losses or changes in asset values. Capital insurance provides financial protection against the loss of capital or investment. It is designed to safeguard the principal amount invested. However, it's crucial to have a well-structured portfolio that aligns with your risk tolerance to weather market fluctuations effectively.",
  },
  {
    title: "What happens when extra profit is made?",
    desc: "Extra profits will be used to pay referral commissions, announce incentives and reward the traders for their diligence. We will also increase our insurance fund.",
  },
  {
    title: "How can I track the performance of my investments?",
    desc: "Once you open an account, you'll have access to a user-friendly dashboard that allows you to monitor the performance of your investments in real-time. You can also receive regular investment reports and updates via email.",
  },
  {
    title: "How long does it take to process transactions?",
    desc: "Generally transactions are processed within minutes. Processing time also depends on the payment method.",
  },
  {
    title: "Can I have an income from my investments?",
    desc: "Yes you can have an income, either from the withdrawal mode or compounding, a later date if income is not needed straight away, this is something we will clarify with you when deciding which is investment is most suitable.",
  },

  {
    title: "How long do I need to tie my money up for?",
    desc: "There is no need to tie your money up for any specific time period and is down to your own personal requirements although it is recommended that stock market related investments should be envisaged to be kept for 3 to 5 years.",
  },

  {
    title: "What risk do I have to take to generate a good return on my money?",
    desc: "Everybody has a different concept of what the word risk means so before recommending an investment, we carry out an ‘Attitude to Risk’ analysis that is personal to you so that your investment is matched to your own personal risk rating plus we then offer regular reviews which includes a review of your attitude to risk in order to ensure that your investment remains suitable.",
  },

  {
    title: "Step by step on how to fund your investment account?",
    desc: `
   1. User should create an account (login)<br>
2. In user's Dashboard there is a dropdown menu icon with options (Deposits).<br>
3. Click on Deposits and you will see an option to Make Deposits.<br>
4. Select an investment plan:<br>
   - BASIC PLAN: $500 - $4,999, 3% weekly ROI, 5% Referral Commission<br>
   - STANDARD PLAN: $5,000 - $29,999, 4.5% weekly ROI, 6% Referral Commission<br>
   - PREMIUM PLAN: $30,000 - $69,999, 6.5% weekly ROI, 7% Referral Commission<br>
   - ZENITH PLAN: $70,000 - $199,999, 8.5% weekly ROI, 8% Referral Commission<br>
   - ELITE PLAN: $200,000 - $1,000,000, 11% weekly ROI, 9% Referral Commission<br>
   - CONTRACT PLAN: $1,000,000 - UNLIMITED, 13.5% weekly ROI, 11% Referral Commission<br>
5. Enter the amount in ($) and confirm the amount you are sending.<br>
6. Click on submit request and the deposit will be successfully initiated! You can make your payment using USDT/USDC or BITCOIN using the wallet address provided, or you can scan the QR Code below to make the payment. Note: Payments should be made within 15 minutes to avoid BTC value changes. Once you are done, send through your personal wallet, Bitcoin ATM, or exchange platforms. Click I HAVE MADE PAYMENT. You will receive a mail within a few minutes of your transaction once it’s confirmed, and your funds will
    `,
  },
  {
    title: "Which countries do you provide consulting services?",
    desc: "As an international company we accept clients from all countries and help them build strong financial stability",
  },
];

export const insightData = [
  {
    img: "",
    title: "Health Insurance",
    desc: "Coverage begins on the first of the month after your hire date. Our provider also offers fun incentives to keep you active and healthy.",
  },
  {
    img: "",
    title: "Dental Insurance",
    desc: "We offer a comprehensive dental package for you and your family",
  },
  {
    img: "",
    title: "Referral bonus",
    desc: "We give incentive that motivates employees / investors to recruit candidates from within their networks.",
  },
  {
    img: "",
    title: "Voluntary Vision Insurance",
    desc: "We offer a comprehensive vision package for you and your family.    ",
  },
  {
    img: "",
    title: "Basic & Voluntary Life Insurance ",
    desc: "We offer basic life insurance paid at 100%. Employees can purchase additional life insurance for themselves, or spouse or dependents.",
  },
  {
    img: "",
    title: "Short-Term & Long-Term Disability",
    desc: "We pay 100% of short term and long term disability plans.",
  },
  {
    img: "",
    title: "Flexible Spending Accounts (FSAs)",
    desc: "Employees can set aside pre-tax dollars to help cover any medical or dependent expenses.",
  },
  {
    img: "",
    title: "401(K)",
    desc: "We offer a dollar-for-dollar match up to 8% for all employees, with full vesting.",
  },
  {
    img: "",
    title: "Group Accident Insurance",
    desc: "We've got you covered with group accident insurance for off-the-job incidents.",
  },
  {
    img: "",
    title: "Personal Time Off (PTO)",
    desc: "Work/life balance is extremely important to us. We offer a substantial amount of time off that increases with tenure.",
  },
  {
    img: "",
    title: "Holidays",
    desc: "In addition to PTO, we observe Financial Institutions & SFIs holidays and encourage family time.",
  },
  {
    img: "",
    title: "Continuing Education",
    desc: "We offer student loan repayment and tuition reimbursement to help with college costs.",
  },
  {
    img: "",
    title: "Employee Assistance Program (EAP)",
    desc: "We offer free programs for mental health, financial counseling, and legal advice.",
  },
  {
    img: "",
    title: "Parental Leave",
    desc: "Planning for a baby or adoption? We offer maternity and paternity leave to support bonding time.",
  },
  {
    img: "",
    title: "Wellness",
    desc: " Quotrack Finance promotes a healthy lifestyle with annual biometric screenings and wellness programs",
  },
];
