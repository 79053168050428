import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Landing from "../Pages/Static/Landing";
import {
  NoAccessRoute,
  AdminAccess,
  ProtectedRoute as AccessRoute,
} from "../components/Private";
import {
  authRoute,
  homeRoute,
  protectedRoute,
  adminProtectedRoute,
} from "../constants/routes";
import NotFound from "../Pages/Static/NotFound";
import WindowWrapper from "../components/window-wrapper";
// import Maintenance from "../Pages/Static/Maintenance";
export default function BrowserLayout() {
  return (
    <Router>
      <WindowWrapper>
        <Routes>
          <Route index path={"/"} element={<Landing />} />
          <Route element={<NoAccessRoute />}>
            {authRoute.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={<route.component />}
              ></Route>
            ))}
          </Route>
          <Route element={<AccessRoute />}>
            {protectedRoute.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={<route.component />}
              ></Route>
            ))}
          </Route>

          <Route element={<AccessRoute />}>
            <Route element={<AdminAccess />}>
              {adminProtectedRoute.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={<route.component />}
                ></Route>
              ))}
            </Route>
          </Route>
          <Route>
            {homeRoute.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={<route.component />}
              ></Route>
            ))}
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </WindowWrapper>
    </Router>
  );
}
