import { useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { PulseLoader, ScaleLoader } from "react-spinners";
import { useMakeDeposit } from "../../../Hooks/useDepositHooks";
import { SelectCoin } from "../../../store/slice/coin";
import { copyToClipboard } from "../../../Utils/functions";
import { authConfig } from "../../../Utils/constants";
import useCountdown from "../../../Hooks/useCountdown";
import { FaTimes } from "react-icons/fa";
import QRCode from "react-qr-code";
import { Button } from "../../../components/Button";

type Props = {
  network: string;
  plan: string;
  closeModal: () => void;
  isOpen: boolean;
};

export default function PayupModal({
  network,
  plan,
  closeModal,
  isOpen,
}: Props) {
  const [clipboardState, setClipboardState] = useState(false);
  const [select, setSelected] = useState<File | null>(null);
  const { deposit } = useMakeDeposit({ closeModal });
  const { data } = useSelector(SelectCoin);
  const [loading, setLoading] = useState(false);
  const [generated, setGenerated] = useState(false);

  const handleSubmit = async (e: any) => {
    try {
      e.preventDefault();
      if (deposit.values.amount) {
        if (select) {
          setLoading(true);
          const formData = new FormData();
          formData.append("file", select!);
          formData.append(
            "upload_preset",
            `${process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET}`
          );
          await axios
            .post(`${process.env.REACT_APP_CLOUDINARY_URL}`, formData)
            .then(async (response) => {
              deposit.values.image = response.data.url;
              deposit.values.plan = plan;
              deposit.handleSubmit();
              setLoading(false);
            });
          return;
        } else {
          toast.error("Select deposit Proof to continue");
        }
      } else {
        toast.error("Amount Field is required");
      }
    } catch (error: any) {
      setLoading(false);
      const message = error?.message || error;
      toast.error(message);
    }
  };

  useEffect(() => {
    if (loading) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [loading]);

  const { minutes, seconds, hasEnded, resetCountdown } = useCountdown(0);
  const {
    minutes: subM,
    seconds: subSec,
    hasEnded: SubEnd,
    resetCountdown: resetCntdnm,
  } = useCountdown(200);

  useEffect(() => {
    if (isOpen) {
      const date = new Date();
      const newDate = date.setSeconds(date.getSeconds() + 20);
      resetCountdown(newDate);
    } else {
      resetCountdown(0);
    }
  }, [isOpen]);

  useEffect(() => {
    if (hasEnded) {
      const date = new Date();
      const newDate = date.setSeconds(date.getSeconds() + 900);
      resetCntdnm(newDate);
    } else {
      resetCntdnm(0);
    }
  }, [hasEnded]);

  return (
    <div className="">
      {!loading && !deposit.isSubmitting && (
        // text-primary bg-white
        <div className=" overflow-scroll dash_webkit pt-0  md:pt-0 w-full md:w-[50vw] text-black md:text-white  md:min-h-[70vh] ">
          <div className="w-full border-4  mt-0 md:mt-10 flex justify-between items-center border-[#e5e5e5] p-3 ">
            <p className=" font-jetbrain font-extrabold w-[30%] ">
              <img src="/images/logo.png" className="w-full" alt="" />
            </p>
            <div className=" text-right text-[12px] md:text-[18px] ">
              <b>Payment Modal</b>
              <p>{authConfig?.appName} - Deposit</p>
            </div>
          </div>
          {!hasEnded && (
            <div>
              <div className=" text-[13px] md:text-[16px] flex flex-col py-10 min-h-[30vh] text-center ">
                A QR-Code invoice with the wallet address for payment will be
                generated/validated in
                <p className=" px-3 pt-10 ">
                  <span className="bg-main md:bg-white text-white md:text-primary font-semibold px-4 py-4 rounded-lg ">
                    {minutes}
                  </span>
                  <span className=" text-2xl mx-2 ">:</span>
                  <span className="bg-main md:bg-white text-white md:text-primary font-semibold px-4 py-4 rounded-lg ">
                    {seconds}
                  </span>
                </p>
              </div>
              <div className=" hidden  text-[14px] md:flex justify-center  items-center  ">
                Please Wait
                <PulseLoader size={5} color="white" />
              </div>
              <div className="  text-[14px] text-primary flex md:hidden justify-center  items-center  ">
                Please Wait
                <PulseLoader size={5} />
              </div>
              <div className="flex justify-center  my-5 items-center ">
                <button
                  onClick={() => {
                    closeModal();
                  }}
                  className=" bg-red-500 text-white font-bold flex items-center gap-2 px-5 py-2 rounded-lg "
                >
                  Cancel <FaTimes />
                </button>
              </div>
            </div>
          )}
          {hasEnded && (
            <>
              <div className=" text-[12px] md:text-[16px] mt-10 px-6 py-6 md:py-10 flex items-center justify-between border-2 ">
                <b>Payment Method</b>
                <div className=" flex items-center ">
                  <img
                    src={`/images/${
                      network === "Bitcoin"
                        ? "bitcoin.svg"
                        : network === "Ethereum"
                        ? "ether.svg"
                        : network === "xrp"
                        ? "xrp.svg"
                        : "tether.svg"
                    }`}
                    className=" h-[30px] w-[40px] "
                    alt=""
                  />
                  {network}
                </div>
              </div>
              <div className=" mt-0 px-6 py-2  text-[12px] md:text-[16px] flex items-center justify-between border-2 ">
                <b>Investment Plan</b>
                <p>{plan}</p>
              </div>
              <div className=" mt-0 px-6 py-2 text-[12px] md:text-[16px]  flex items-center justify-between border-2 ">
                <p className="flex">
                  <span className=" hidden md:block ">Minimum</span>
                  <span className=" block md:hidden pr-1 ">Min:</span>
                  <span className=" hidden md:block pl-1 ">Amount:</span>
                  {plan === "starter"
                    ? " $500"
                    : plan === "prime"
                    ? " $2,000"
                    : plan === "pro"
                    ? " $50,000"
                    : plan === "elite"
                    ? "  $200,000"
                    : " $1,000,000"}
                </p>
                <p className=" flex text-[12px] md:text-[16px] ">
                  <span className=" hidden md:block ">Maximum</span>
                  <span className=" block md:hidden pr-1 ">Max:</span>
                  <span className=" hidden md:block pl-1 ">Amount:</span>

                  {plan === "starter"
                    ? "$1,999"
                    : plan === "prime"
                    ? "49,999"
                    : plan === "pro"
                    ? " $199,999"
                    : plan === "elite"
                    ? " $1,000,000"
                    : " Unlimited"}
                </p>
              </div>
              <div className=" bg-main py-3  text-[12px] md:text-[16px] ">
                <div className=" min-h-[60px] p-3 md:px-7 bg-[#e5e5e5] ">
                  <div className="flex justify-center items-center flex-col py-2 ">
                    <p className=" py-4 pb-5 text-black font-semibold text-[13px] md:text-[16px] w-[80%] md:w-full text-center ">
                      <i>
                        Scan the QR-Code and copy the wallet address for your{" "}
                        <span className=" py-4 pb-5 text-[13px] md:text-[16px] w-[80%] md:w-full text-center ">
                          {network}{" "}
                        </span>
                        payment deposit
                      </i>
                    </p>
                    <QRCode
                      value={
                        network === "Bitcoin"
                          ? data?.btc
                          : network === "Ethereum"
                          ? data?.ethereum
                          : network === "xrp"
                          ? data?.xrp
                          : network === "usdt"
                          ? data?.usdt
                          : ""
                      }
                      viewBox={`0 0 256 256`}
                    />
                    <div className=" text-black py-3 font-bold ">
                      Payment Ends In {subM}:{subSec}{" "}
                    </div>
                  </div>

                  <div className="flex text-center text-black justify-center text-[20px] font-bold ">
                    OR
                  </div>

                  <Button
                    className=" cursor-pointer bg-primary text-white block mx-auto px-5 py-2 my-3 overflow-scroll dash_webkit "
                    onClick={() => {
                      copyToClipboard(
                        network === "Bitcoin"
                          ? data?.btc
                          : network === "Ethereum"
                          ? data?.ethereum
                          : network === "xrp"
                          ? data?.xrp
                          : network === "usdt"
                          ? data?.usdt
                          : ""
                      );
                      setClipboardState(true);
                      toast.success("Copied");
                    }}
                  >
                    Click To Copy Address and Make Payment
                  </Button>
                </div>
              </div>
              <div className="  mt-0 px-6  py-4  flex flex-col text-[12px] md:text-[16px]   border-2">
                <b className="pb-3">Proof Of Payment:</b>
                <div className="p-4 bg-[#e5e5e5] overflow-x-scroll dash_webkit  rounded-lg ">
                  <input
                    onChange={(e) => {
                      if (e.target.files) {
                        setSelected(e.target.files[0]);
                      }
                    }}
                    name="image"
                    type="file"
                    className=" text-black pt-0"
                  />
                </div>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="  mt-0 px-6  py-4  flex flex-col text-[12px] md:text-[16px]   border-2">
                  <b className="pb-3">Amount Deposited:</b>
                  <p className=" text-red-500 text-right ">
                    {deposit.errors.amount}
                  </p>
                  <div className="p-4 bg-[#e5e5e5] rounded-lg ">
                    <input
                      onChange={deposit.handleChange}
                      value={deposit.values.amount}
                      name="amount"
                      onBlur={deposit.handleBlur}
                      placeholder="Amount"
                      type="number"
                      className="bg-[#e5e5e5] text-black outline-none  w-full pt-0"
                    />
                  </div>
                </div>
                <div className=" pt-10 flex gap-5 justify-center md:justify-end items-center ">
                  <button
                    type="button"
                    className=" py-3 px-10 border-2 rounded-lg border-[#d9bf84] "
                    aria-label="Discard_button"
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="py-4 px-10 text-white bg-main rounded-lg"
                    aria-label="Save_button"
                  >
                    Save
                  </button>
                </div>
              </form>
            </>
          )}
        </div>
      )}
      {loading && (
        <div className=" flex px-32 justify-center items-center ">
          <div className="hidden md:block">
            <ScaleLoader color="white" />
          </div>
          <div className="block md:hidden">
            <ScaleLoader />
          </div>
        </div>
      )}
      {deposit.isSubmitting && (
        <div className=" flex px-32 justify-center items-center ">
          <ScaleLoader color="white" />
        </div>
      )}
    </div>
  );
}
