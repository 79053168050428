import { PdfCom } from "../../components/PdfComp";
import LayoutOne from "../../Layout/Layout_One";

export default function Whitepaper() {
  return (
    <LayoutOne>
      <PdfCom file="/pdf/white-paper.pdf" />
    </LayoutOne>
  );
}
