import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { PiArrowRight, PiEyeLight, PiEyeSlashLight } from "react-icons/pi";
import useAuthHooks from "../../Hooks/authHooks";
import { Button } from "../../components/Button";
import { routeObj } from "../../constants/routes";
import { FormInput } from "../../components/Input";
import Header from "../../Layout/component/Header";

export default function ResetPassword() {
  const { reset } = useAuthHooks();
  const [showPassword, setShowPassword] = useState(false);
  const { reset: resetToken } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (resetToken) {
      reset.setFieldValue("token", resetToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetToken]);

  return (
    <div className="relative">
      <div className="absolute top-2 left-2 z-10  md:text-xl bg-red-00 text-main">
        <img
          onClick={() => navigate("/")}
          src="/images/logo.png"
          alt=""
          className=" w-[100px] md:w-[150px]  "
        />
      </div>
      <div className="flex  text-black">
        <div
          className="h-[100vh] relative hidden  md:flex bg-[98%] w-[40%]  justify-center items-center  bg-no-repeat b py- px-5 bg-cover  "
          style={{ backgroundImage: `url('/images/login.png')` }}
        >
          <img
            onClick={() => navigate("/")}
            src="/images/login2.png"
            alt=""
            className=" absolute bottom-0 left-0 "
          />
        </div>
        <div className=" relative flex h-[100vh] w-full md:w-[100%] bg-[#EDF4FF py-20 md:py-0 justify-center md:items-center ">
          <div className=" md:hidden absolute top-5 left-4 "></div>
          <div className=" h-fit bg-red-00 w-[90%] md:w-3/4  ">
            <div className=" flex md:items-center flex-col md:flex-row justify-between pb-12 md:pb-0 ">
              <div className=" md:w-full  md:text-center  md:text-[24px] font-bold py-4">
                Reset Password
              </div>
            </div>
            <form onSubmit={reset.handleSubmit}>
              <FormInput
                label="New Password"
                className="rounded-lg  bg-[#EDF4FF"
                name="password"
                error={reset.errors.password}
                value={reset.values.password}
                icon={showPassword ? PiEyeLight : PiEyeSlashLight}
                onBlur={reset.handleBlur}
                onChange={reset.handleChange}
                type={showPassword ? "text" : "password"}
                onClick={() => {
                  {
                    showPassword
                      ? setShowPassword(false)
                      : setShowPassword(true);
                  }
                }}
              />
              <FormInput
                label="Confirm Password"
                className="rounded-lg  bg-[#EDF4FF"
                name="password_confirmation"
                error={reset.errors.password_confirmation}
                value={reset.values.password_confirmation}
                icon={showPassword ? PiEyeLight : PiEyeSlashLight}
                onBlur={reset.handleBlur}
                onChange={reset.handleChange}
                type={showPassword ? "text" : "password"}
                onClick={() => {
                  {
                    showPassword
                      ? setShowPassword(false)
                      : setShowPassword(true);
                  }
                }}
              />

              <Button
                type="submit"
                className=" bg-primary justify-center gap-3 m-auto w-full md:w-fit flex items-center  text-white px-16 py-3 font-bold "
                loading={reset.isSubmitting}
              >
                Continue <PiArrowRight />
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
