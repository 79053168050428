import { ReactNode, useEffect } from "react";
import Header from "./component/Header";
import Footer from "./component/Footer";
import Translate from "../Pages/Static/component/Translate";
import { useLocation, useNavigate } from "react-router-dom";

export default function LayoutOne({
  children,
  shadow,
}: {
  children: ReactNode;
  shadow?: boolean;
}) {
  const pathname = useLocation().pathname;
  return (
    <div className=" relative  ref_sec">
      {/* {pathname === "/" && <Translate />} */}
      <div
        className={` bg  fixed w-[100%] bg-red-00 flex items-center justify-center z-10 top-6  ${
          shadow && "bg-[#F7F9FB"
        }  `}
      >
        <Header shadow={shadow!} />
      </div>
      <div className=" ref_sec overflow-scroll  mt-[-20px] text-black  ">
        <div className=" w-[100%]  min-h-full ">
          <div className=" pb-[10px] font-jetbrain  ">{children}</div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
