import { createElement } from "react";
import { Link, useLocation } from "react-router-dom";

interface NavItemProps {
  name: string;
  link: string;
  icon?: any;
  url?: string;
}

export const NavLink = ({ link, name }: NavItemProps) => {
  const pathname = useLocation().pathname;

  return (
    <Link to={link}>
      <div
        className={`w-fit text-[14px] ${
          pathname.includes(link)
            ? " text-sm text-primary font-semibold  ml-0 md:ml-0 "
            : ""
        }`}
      >
        {name}
      </div>
    </Link>
  );
};

export const DashboardNavLink = ({ link, name, icon }: NavItemProps) => {
  return (
    <Link
      className={" text-sm "}
      to={link}
      target={link.includes("Docs") ? "_blank" : "_self"}
    >
      <div
        className={` ${
          name.includes("Home")
            ? "bg-[#2d2a2a] md:border-r-0 "
            : " shadow-[#4a345d] shadow-md"
        }  px-2 md:px-7 md:w-fit py-3 rounded-full  flex items-center gap-3 text-primary  `}
      >
        <p className=" text-[16px] ">{createElement(icon!)}</p> {name}
      </div>
    </Link>
  );
};

export const DashboardSideNavLink = ({ link, name, icon }: NavItemProps) => {
  const pathname = useLocation().pathname;
  return <Element icon={icon} name={name} pathname={pathname} url={link!} />;
};

const Element = ({
  pathname,
  icon,
  url,
  name,
}: {
  pathname: string;
  url: string;
  icon: any;
  name: string;
}) => (
  <Link to={url}>
    <div
      className={`flex border-b-2 md:border-b-0 py-5 md:py-4 mt-0 md:mt-5 hover:md:rounded-tl-3xl hover:md:rounded-bl-3xl  hover:bg-white/15 hover:text-light hover:pt-3 hover:text w-full items-center gap-5 px-5   text ${
        pathname === url
          ? "font-semibold text-[18px] border-r-0 md:rounded-tl-3xl md:rounded-bl-3xl  bg-[#F8F8F8] text-primary pt-3 w-full md:text-[16px] ml-0 md:ml-0 "
          : ""
      }`}
    >
      <div className="text-[25px] ">{icon && createElement(icon)}</div> {name}
    </div>
  </Link>
);
