import moment from "moment";

const date = new Date();
export const Today = moment(date).format("MMMM Do YYYY, h:mm:ss a");

export const Fmtd_Date = (value: string) => {
  return moment(value).format("MMMM Do YYYY");
  // return moment(value).format("MMMM Do YYYY, h:mm:ss a");
};

export const copyToClipboard = (value: string) => {
  if (value) {
    navigator.clipboard.writeText(value.toString()).then(() => {
      return true;
    });
  }
  return false;
};

export const generateTableRandomColor = () => {
  const d = Math.floor(Math.random() * 5 + 1);
  switch (d) {
    case 1:
      return "#FFFFF0";
    case 2:
      return "#FFF5EE";
    case 3:
      return "#F0EAD6";
    case 4:
      return "#EDEAE0";
    case 5:
      return "#e1e1e1";
    default:
      return "#ffffff";
  }
};

export function objectToQueryString(obj: any) {
  return Object.keys(obj)
    .reduce(function (a: string[], k: string) {
      a.push(k + "=" + encodeURIComponent(obj[k]));

      return a;
    }, [])
    .join("&");
}

export const replaceSpecialCharsWithSpace = (inputString: string) => {
  // Using regular expression to replace all occurrences of _, -, and / with space
  if (inputString) {
    return inputString.replace(/[_\-/]/g, " ");
  }
};

export const trimSentence = (sentence: string, no_of_word: number) => {
  const words = sentence.split(" ");

  if (words.length <= no_of_word) {
    return sentence;
  }
  const trimmedSentence = words.slice(0, no_of_word).join(" ") + "...";

  return trimmedSentence;
};

const formatter = new Intl.NumberFormat();

export const numberFormatter = (num: number) => {
  // Use the formatter to format the number as a string
  if (typeof num === "number" || typeof num === "string") {
    return formatter.format(num);
  }
};

export const getInitials = (name: string) => {
  if (name) {
    let words = name.split(" ");

    let initials = words.map((word) => word[0]).join("");

    return initials;
  }
  return "NA";
};

export const are_you_3 = [
  { dsc: "Are you saving for a house?" },
  { dsc: "Are you trying to sort out your debts?" },
  { dsc: "Do you need to save for a rainy day?" },
  { dsc: "When are you planning to retire?" },
  { dsc: "Are you planning to buy a new car?" },
  { dsc: "Do you need to find a good pension?" },
  { dsc: "Are you looking for a better return for your investments?" },
];

export const are_you_1 = [
  { dsc: "What major life events are you saving for in the next few years?" },
  { dsc: "Are you planning to purchase a new home or property?" },
  { dsc: "Do you have any plans to start or expand a business?" },
  {
    dsc: "Are you interested in funding higher education for yourself or your family?",
  },
  {
    dsc: "Do you have specific retirement lifestyle goals, such as travel or relocating?",
  },
  { dsc: "Are you planning any large charitable donations or contributions?" },
  { dsc: "How important is leaving a financial legacy for your loved ones?" },
];

export const are_you_2 = [
  {
    dsc: "What is your current housing situation, and do you anticipate any changes?",
  },
  {
    dsc: "Do you have any outstanding loans or debts you're focused on repaying?",
  },
  {
    dsc: "What is your monthly budget, and how does it align with your income?",
  },
  {
    dsc: "Are you currently investing, and if so, what is your risk tolerance?",
  },
  { dsc: "How safe is your job?" },
  { dsc: "Do you have an emergency fund or savings plan in place?" },
  {
    dsc: "Are you currently managing any financial obligations for others, such as dependents or family members?",
  },
];

export const isValidEmail = (email: string) => {
  // Define the regex pattern for a valid email
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (email) {
    return pattern.test(email);
  }
  return false;
  // Test the email against the pattern
};
