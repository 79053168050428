import React from "react";
import LayoutOne from "../../Layout/Layout_One";
import { Button } from "../../components/Button";
import { Link } from "react-router-dom";
import { MdLockPerson, MdPayments } from "react-icons/md";
import { TiGlobe } from "react-icons/ti";
import { routeObj } from "../../constants/routes";
import { BiBriefcaseAlt } from "react-icons/bi";
import { authConfig } from "../../Utils/constants";
import { FaCircleCheck } from "react-icons/fa6";

export default function About() {
  return (
    <LayoutOne>
      <div className="  bg-red-00  ">
        <div className=" flex justify-center items-center mt-[180px] md:mt-[200px] ">
          <div className=" bg-[#FFFFFF] border-[1px] rounded-full border-primary w-fit px-5 py-2 ">
            About Us
          </div>
        </div>
        <div className=" py-10">
          <p className="text-center  text-xl md:text-3xl font-normal md:w-[70%] mx-auto  ">
            Our mission is to generate world-class investment returns over the
            long term.
          </p>
        </div>
        <p className=" w-[90%] md:w-[50%] text-[13px] md:text-[16px] text-center mx-auto  pb-10 ">
          We aspire to do so in a way that makes our partners and portfolio
          companies proud, as we build a unique, global investment company.
        </p>
        <div className="flex bg-red-00 px-5 md:px-0 items-center justify-center ">
          <img src="/images/aboutp.jpg" alt="" />
        </div>
        <div className=" py-7 md:py-20 px-5 md:px-20 flex flex-col md:flex-row justify-between ">
          <h2 className="  md:text-2xl w-[30%] font-bold pb-6 ">About us</h2>
          <div className="text-sm w-full md:w-[70%] leading-[30px] md:text-[16px]">
            Quotrack Finance is an investment blockchain wealth management fund,
            we are an emerging portfolio management firm, that allows
            traditional investors to identify and invest in a diversified
            portfolio of digital assets across the decentralized finance, Web
            3.0, and the crypto mining aspect using green energy to mine
            cryptocurrency with a committed ESG strategy. We are the only
            publicly-traded company built to give investors direct exposure to
            these nascent markets. Quotrack Finance lies at the heart of
            financial innovation. On behalf of our shareholders and investors,
            we identify opportunities and areas of innovation and build and
            invest in new technologies and ventures to provide diversified
            exposure across decentralized finance opportunities generating world
            class investment returns over the long term. We seek to grow and
            safeguard the investments of all our clients in our blockchain
            system for maximum security and trust.
          </div>
        </div>

        <div className=" py-7 md:py-10 bg-[#FFE6DA] mx-5 md:mx-20 mb-10 p-5 md:rounded-3xl flex justify-between ">
          <h2 className=" hidden md:block  md:text-2xl w-[30%] font-bold pb-6 "></h2>
          <div className="text-sm w-full md:w-[70%] flex flex-col gap-7 leading-[30px] md:text-[16px]">
            <p className="">
              Consistent profits will be generated as long as there's volatility
              in Bitcoin and other cryptocurrencies prices. It does not matter
              if you have extensive experience in the markets or are new to it,
              Quotrack Chain will provide you with insightful information,
              analysis and judgments on the current markets.
            </p>

            <p className="">
              The mission of our company is to provide a reliable and profitable
              management system for all members. We seek to grow and safeguard
              the investments of all our clients in our blockchain system for
              maximum security and trust. The whole trading system is built on
              state of art quantitative models.
            </p>
          </div>
        </div>

        <div className=" py-7 md:py-10 gap-10 mx-5 md:mx-20 md:mb-10 md:p-5 rounded-3xl flex flex-col md:flex-row justify-between ">
          <div className=" relative bg-[#E0FFFE] min-h-[300px] rounded-lg  p-6 w-full  md:w-[50%]  pb-6 bg-[15%]   ">
            <img
              className=" absolute bottom-0 right-0 "
              src="/images/about1.png"
              alt=""
            />
            <p className=" text-[16px] font-[550] ">Management team</p>
            <p className="text-sm py-2 w-[90%] ">
              Quotrackfinance.net is a group of experts who put and dispense
              your assets that make the best long haul returns for you.
            </p>
          </div>

          <div className=" relative bg-[#EAFFCA] min-h-[300px] rounded-lg  p-6 w-full  md:w-[50%]  pb-6 bg-[15%]   ">
            <img
              className=" absolute bottom-0 right-0 "
              src="/images/about2.png"
              alt=""
            />
            <p className=" text-[16px] font-[550] ">Secure investments</p>
            <p className="text-sm py-2 w-[90%] ">
              We were able to balance risks and profit levels using advances and
              highly qualified employees of the company.
            </p>
          </div>
        </div>

        <div className=" py-7 md:py-10 mx-5 md:mx-20 md:mb-10 md:p-5 rounded-3xl gap-10 flex flex-col md:flex-row justify-between ">
          <div className=" w-full md:w-[50%]">
            <h2 className="  md:text-2xl  font-[550] pb-6 ">
              Our mission statement
            </h2>

            <p className="">
              Quotrack Finance specializes in providing private, corporate, and
              institutional clients with personalized wealth management and
              trading strategies by combining “Global Focus, Local Reach” with
              institutional capabilities. Our clients benefit from our
              competitive pricing, execution and customized financial services.
            </p>
          </div>
          <div className="text-sm  w-full md:w-[50%] ">
            <img src="/images/about3.png" alt="" />
          </div>
        </div>

        <div className=" py-0 relative md:py-10 mx-5 md:mx-20 md:mb-10 md:p-5 rounded-3xl gap-10 flex flex-col md:flex-row  justify-between ">
          <div className="text-sm hidden md:block w-full md:w-[45%] relative ">
            <img
              src="/images/about4.png"
              alt=""
              className="md:absolute -top-[400px]"
            />
          </div>
          <div className=" w-full md:w-[55%]">
            <p className="">
              Our driving force is our commitment to providing our clients with
              the highest level of quality advice by adhering to our core values
              of Trust, Integrity and Excellence.
            </p>
          </div>
          <div className="text-sm w-full block md:hidden md:w-[45%] relative ">
            <img
              src="/images/about4.png"
              alt=""
              className="md:absolute -top-[400px]"
            />
          </div>
        </div>

        <div className=" py-7 md:py-10 mx-5 md:mx-20 md:mb-10 md:p-5 md:mt-28 rounded-3xl gap-10 bg-red-00 flex flex-col md:flex-row  justify-between ">
          <div className=" w-full md:w-[50%]">
            <h2 className="  md:text-2xl  font-[550] pb-4 md:pb-6 ">
              Our values
            </h2>

            <p className="">
              Trust, Integrity and Excellence are the pillars on which Quotrack
              Finance is built. Those qualities are embodied by our financial
              professionals, who have extensive training and industry
              experience.
            </p>
            <div className="flex flex-col my-5 gap-4  ">
              <div className="flex gap-3 items-center ">
                <FaCircleCheck className=" text-wite" /> Trust
              </div>
              <div className="flex gap-3 items-center ">
                <FaCircleCheck /> Integrity
              </div>{" "}
              <div className="flex gap-3 items-center ">
                <FaCircleCheck /> Excellence
              </div>
            </div>
          </div>
          <div className="text-sm w-full md:w-[50%] ">
            <img src="/images/about5.png" alt="" />
          </div>
        </div>

        <div className=" py-7 relative md:py-10 mx-5 md:mx-20 md:mb-5 md:p-5 rounded-3xl gap-10 flex justify-between ">
          <p className=" md:text-3xl text-center font-[500] ">
            We provide financial services expertise to high net worth
            individuals, institutional customers, and family offices
          </p>
        </div>

        <div className=" py-7  md:py-10 mx-5 md:mx-20  rounded-3xl flex flex-col gap-5   ">
          <div className="flex flex-col md:flex-row justify-between gap-5">
            <div className="w-full md:w-[80%] rounded-lg p-5 bg-[#EAFFCAB2]">
              <h2 className="  md:text-2xl  font-[550] pb-4 ">Principles</h2>

              <p className="font-light">
                Our company is based on the principle that education and
                understanding of your current financial situation is vital to
                successfully make prudent decisions concerning your future
                financial condition. If you have any questions about your
                current financial situation or wish to schedule an appointment,
                please contact us. Depending on your location preference, please
                email
              </p>
            </div>
            <div className="text-sm w-full md:w-[50%] p-5 rounded-lg bg-[#E8EAE5B2] ">
              <h2 className="  md:text-2xl  font-[550] ">Core Principles</h2>
              <div className="flex flex-col my-5 gap-4  ">
                <div className="flex gap-3 items-center font-light ">
                  <FaCircleCheck className=" text-wite" /> Dedication to Clients
                </div>
                <div className="flex gap-3 items-center font-light ">
                  <FaCircleCheck /> Innovation
                </div>{" "}
                <div className="flex gap-3 items-center font-light ">
                  <FaCircleCheck /> Professionalism
                </div>
                <div className="flex gap-3 items-center font-light ">
                  <FaCircleCheck /> A relationship-driven approach to business
                </div>
              </div>
            </div>
          </div>
          <div className=" rounded-3xl gap-5 flex flex-col md:flex-row  justify-between ">
            <div className="text-sm md:w-[40%] ">
              <img src="/images/about6.png" alt="" />
            </div>
            <div className="w-[100%] rounded-lg p-8 bg-[#FFE6DAB2]">
              <h2 className="  md:text-2xl  font-[550] pb-4 ">Commitment</h2>

              <p className="font-light">
                Our staff consists of experienced professionals with a "hands
                on" approach to financial guidance. Not only will you find our
                team members knowledgeable, but you will also discover that our
                staff truly cares about making your dreams a reality. As your
                Financial Professionals, we will do everything in our power to
                keep you focused on where you want to go, advise you on how to
                get there, and continually remind you of the importance of
                maintaining a disciplined approach to realizing your dreams.
              </p>
            </div>
          </div>
        </div>
      </div>
    </LayoutOne>
  );
}
