import React, { useState } from "react";
import LayoutOne from "../../Layout/Layout_One";
import { MdLocationOn, MdMessage, MdPhone } from "react-icons/md";
import { FormInput } from "../../components/Input";
import { Button } from "../../components/Button";
import { Formik } from "formik";
import { authConfig } from "../../Utils/constants";

export default function Contact() {
  const [name, setName] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  const initialValues: {
    fullname: string;
    email: string;
    subject: string;
    message: string;
  } = { email: "", subject: "", message: "", fullname: "" };
  return (
    <LayoutOne shadow={true}>
      <div
        className=" bg-center min-h-[50vh] md:h-[100vh]  bg-cover bg-no-repeat "
        style={{ backgroundImage: `url('/images/contact.jpeg')` }}
      >
        <div className=" h-full py-5 text-center md:text-left  pt-[180px] bg-rgba justify-center flex ">
          <div className=" w-[80%] md:w-[40%] py-10 md:py-20">
            <h1 className=" md:text-5xl font-semibold text-white ">
              Get In Touch
            </h1>
            <div className="text-white text-[12px] md:text-[15px] font-extralight py-4 pb-4 md:pb-10">
              <p className=" hidden md:block ">Have a question or feedback?</p>
              <p className="py-2">
                Fill out the form <span className="  md:hidden ">below</span>{" "}
                and we will be in touch
              </p>
            </div>
            <div className="md:hidden flex flex-col md:flex-row py-5 text-white w-fit md:w-full m-auto items-center gap-2 ">
              <p className="  font-bold ">Have a question or feedback?</p>
              {/* <p className=" text-[13px]  md:text-[15px] ">
                We now offer after-hours support about your deposit and
                withdrawal and need to speak to a representative after normal
                business hours, please contact our After Hours Contact Center
                at: ‪+44 7429 358530
              </p> */}
            </div>
            <div className="text-[12px] md:text-[15px] text-center md:text-left ">
              <div className=" hidden md:flex text-white m-auto w-fit md:w-full items-center gap-2 ">
                {/* <MdPhone /> <p>+44 7429 358530</p> */}
              </div>
              <div className=" flex py-5 text-white w-fit md:w-full m-auto items-center gap-2 ">
                <MdMessage /> <p>{authConfig.email}</p>
              </div>

              <div className=" flex flex-col md:flex-row py-5 text-white w-fit md:w-full m-auto items-center gap-2 ">
                <MdLocationOn className=" hidden md:block " />{" "}
                <p className=" md:hidden font-bold ">Our Location</p>
                <p>{authConfig.address}</p>
              </div>
            </div>
          </div>
          <div className=" bg-ligh hidden md:block   w-[40%] ">
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              onSubmit={async (values) => {
                // console.log(values);
                setEmail("");
                setName("");
                setMessage("");
                setSubject("");
              }}
            >
              {(prop) => (
                <form
                  onSubmit={prop.handleSubmit}
                  className="flex flex-col rounded-lg  bg-light gap-4 p-5"
                >
                  <div className=" py-[10px] px-[10px] w-full md:w-full h-[70px] bg-[#ffffff] ">
                    <input
                      className=" font-semibold h-full w-full bg-[#ffffff] placeholder:text-[#4e4e4e] placeholder:text-[14px] outline-none "
                      placeholder="Full Name *"
                      name="fullname"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className=" py-[10px] w-full px-[10px] md:w-full h-[70px] bg-[#ffffff] ">
                    <input
                      className=" font-semibold placeholder:text-[#4e4e4e] h-full w-full bg-[#ffffff] placeholder:text-[14px] outline-none "
                      placeholder="Email *"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="py-[10px] w-full px-[10px] h-[200px] bg-[#ffffff]">
                    <textarea
                      className="font-semibold placeholder:text-[#4e4e4e] h-full w-full bg-[#ffffff] placeholder:text-[14px] outline-none "
                      placeholder="Message *"
                      name="message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>
                  <Button
                    className=" bg-primary px-10 py-3 rounded-xl text-white mt-[10px]  "
                    type="submit"
                  >
                    Submit
                  </Button>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <div className=" bg-light  md:hidden  rounded-lg  ">
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={async (values) => {
            // console.log(values);
            setEmail("");
            setName("");
            setMessage("");
            setSubject("");
          }}
        >
          {(prop) => (
            <form
              onSubmit={prop.handleSubmit}
              className="flex flex-col gap-4 p-5"
            >
              <div className=" py-[10px] px-[10px] w-full md:w-full h-[70px] bg-[#ffffff] ">
                <input
                  className=" font-semibold h-full w-full bg-[#ffffff] placeholder:text-[#4e4e4e] placeholder:text-[14px] outline-none "
                  placeholder="Full Name *"
                  name="fullname"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className=" py-[10px] w-full px-[10px] md:w-full h-[70px] bg-[#ffffff] ">
                <input
                  className=" font-semibold placeholder:text-[#4e4e4e] h-full w-full bg-[#ffffff] placeholder:text-[14px] outline-none "
                  placeholder="Email *"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="py-[10px] w-full px-[10px] h-[200px] bg-[#ffffff]">
                <textarea
                  className="font-semibold placeholder:text-[#4e4e4e] h-full w-full bg-[#ffffff] placeholder:text-[14px] outline-none "
                  placeholder="Message *"
                  name="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
              <Button
                className=" bg-primary px-10 py-3 rounded-xl text-white mt-[10px]  "
                type="submit"
              >
                Submit
              </Button>
            </form>
          )}
        </Formik>
      </div>
      <div className=" text-center py-20 ">
        <p className="text-primary">OUR LOCATION</p>
        <p className="font-bold md:text-2xl py-5 md:py-10 ">
          Connecting near and far
        </p>
        <div className=" w-[95%]  md:w-[80%] m-auto text-black  ">
          <div className=" h-[600px] ">
            <iframe
              title="my map"
              className="mapdf"
              width="100%"
              height="100%"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Office:%203%20Shenton%20Way%2015-07%20Shenton%20House,%20Singapore+(Quotrack%20Finance)&amp;t=h&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              // <iframe width="600" height="450" style="border:0" loading="lazy" allowfullscreen
              // src="https://www.google.com/maps/embed/v1/place?q=12%20John%20Princes%20Street%2C%20London%2C%20W1G%200JR&key=..."></iframe>
            ></iframe>
          </div>
        </div>
      </div>
    </LayoutOne>
  );
}

{
  /* <div style="width: 100%"><iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Office:%203%20Shenton%20Way%2015-07%20Shenton%20House,%20Singapore+(Quotrack%20Finance)&amp;t=h&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps systems</a></iframe></div> */
}
