import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { PiEyeLight, PiEyeSlashLight } from "react-icons/pi";
import useAuthHooks from "../../Hooks/authHooks";
import { Button } from "../../components/Button";
import { routeObj } from "../../constants/routes";
import { FormInput, SelectInput } from "../../components/Input";
import Header from "../../Layout/component/Header";
import countries from "../../Utils/countries.json";

export default function SignUp() {
  const { register } = useAuthHooks();
  const [searchParams] = useSearchParams();
  const [country, setCountries] = useState<Array<string>>();
  const [showPassword, setShowPassword] = useState(false);
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    try {
      setCountries(Object.keys(countries));
    } catch (error) {
      console.log(error as TypeError);
    }
  }, []);

  useEffect(() => {
    const code = searchParams.get("referral");
    if (code) {
      register.setFieldValue("referral", code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <div className="relative">
      <div className="absolute top-2 left-2 z-10  md:text-xl bg-red-00 text-main">
        <img
          onClick={() => navigate("/")}
          src="/images/logo.png"
          alt=""
          className=" cursor-pointer w-[100px] md:w-[150px]  "
        />
      </div>
      <div className="flex  text-black">
        <div
          className="h-[100vh] relative hidden  md:flex bg-[98%] w-[40%]  justify-center items-center  bg-no-repeat b py- px-5 bg-cover  "
          style={{ backgroundImage: `url('/images/login.png')` }}
        >
          <img
            onClick={() => navigate("/")}
            src="/images/login2.png"
            alt=""
            className=" absolute bottom-0 left-0 "
          />
        </div>
        <div className=" relative flex min-h-[100vh] w-full md:w-[100%] py-10 md:py-20 bg-[#EDF4FF justify-center items-center ">
          <div className=" md:hidden absolute top-5 left-4 "></div>
          <div className=" h-fit bg-red-00 w-[90%] md:w-3/4  ">
            <div className=" flex items-center flex-col md:flex-row justify-between pb-12 md:pb-0 ">
              <div className="md:w-full  md:text-center  md:text-[24px] font-bold py-4">
                Register
              </div>
              <div className=" whitespace-nowrap text-xs md:text-sm ">
                Already registered?{" "}
                <Link to={routeObj.login}>
                  <span className=" font-bold text-primary ">Sign In</span>
                </Link>
              </div>
            </div>
            <form
              onSubmit={register.handleSubmit}
              className="overflow-scroll block md:pt-10 bg-red00 no_scrollbar md:h-[80vh]"
            >
              <FormInput
                label="Full Name"
                className="rounded-lg  bg-[#EDF4FF"
                name="fullname"
                error={register.errors.fullname}
                value={register.values.fullname}
                onBlur={register.handleBlur}
                onChange={register.handleChange}
              />
              <FormInput
                label="Email"
                className="rounded-lg  bg-[#EDF4FF"
                name="email"
                error={register.errors.email}
                value={register.values.email}
                onBlur={register.handleBlur}
                onChange={register.handleChange}
              />
              <FormInput
                label="Password"
                className="rounded-lg  bg-[#EDF4FF"
                name="password"
                type={showPassword ? "text" : "password"}
                icon={showPassword ? PiEyeLight : PiEyeSlashLight}
                error={register.errors.password}
                value={register.values.password}
                onBlur={register.handleBlur}
                onChange={register.handleChange}
                onClick={() => {
                  {
                    showPassword
                      ? setShowPassword(false)
                      : setShowPassword(true);
                  }
                }}
              />
              <FormInput
                label="Phone Number"
                className="rounded-lg  bg-[#EDF4FF"
                name="phone"
                error={register.errors.phone}
                value={register.values.phone}
                onBlur={register.handleBlur}
                onChange={register.handleChange}
              />{" "}
              <SelectInput
                error={register.errors.country!}
                onBlur={register.handleBlur}
                onChange={register.handleChange}
                value={register.values.country}
                className="rounded-lg w-[95%]  bg-[#EDF4FF"
                label="Country"
                name="country"
              >
                {country?.map((item, index) => (
                  <option
                    value={item}
                    key={`country_${index}`}
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      marginLeft: "10px",
                    }}
                  >
                    {item}
                  </option>
                ))}
              </SelectInput>
              <FormInput
                label="Referral Code"
                className="rounded-lg  bg-[#EDF4FF"
                name="referral"
                error={register.errors.referral}
                value={register.values.referral}
                onBlur={register.handleBlur}
                onChange={register.handleChange}
              />
              <div className="flex items-center  gap-4 mt-5 ">
                <input
                  type="checkbox"
                  className=" accent-black "
                  onChange={() => {
                    setChecked(!checked);
                  }}
                  checked={checked}
                />
                <p className=" pt-[3px] text-[13px]  ">
                  I've Read and Agreed To The Terms of Service
                </p>
              </div>
              <Button
                disabled={!checked}
                type="submit"
                className=" bg-primary block m-auto w-full md:w-fit my-5  text-white px-16 py-3 font-bold "
                loading={register.isSubmitting}
              >
                Register
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
