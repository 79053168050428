import { useNavigate } from "react-router";
import DashboardLayout from "../../../Layout/DashBoardLayout";
import { inv_types } from "../../../Utils/data";
import { routeObj } from "../../../constants/routes";
import { Link } from "react-router-dom";
import { Button } from "../../../components/Button";
import { FaCircleCheck } from "react-icons/fa6";

export default function DepositOptions() {
  const navigate = useNavigate();
  return (
    <DashboardLayout>
      <p className=" md:text-[20px] text-primary text-center md:text-left font-[700] ">
        Choose Your Investment Package
      </p>
      <p className=" text-[13px] md:text-[15px] pt-2 pb-5 text-center md:text-left  ">
        Discover our selection of five exclusive investment packages,
        thoughtfully crafted to meet diverse financial goals and budgets. Each
        package outlines transparent minimum and maximum investment thresholds,
        empowering you to choose the perfect fit for your financial aspirations.
        With every plan, you gain access to expert portfolio management,
        cutting-edge strategies, and a dedicated focus on maximizing your
        returns. Explore the packages below to find the ideal match for your
        ambitions and take the first step toward realizing your financial
        success with Quotrack Finance.
      </p>
      <div className=" flex flex-wrap justify-between gap-10 ">
        {inv_types?.map((item, index) => (
          <div
            key={index}
            style={{ background: item.bg }}
            className={`w-[90%] mx-auto md:w-[30%]  rounded-lg shadow-md py-5  px-5 flex flex-col gap-6 `}
          >
            <p className="   bg-yellow-00 font- text-l ">{item.plan}</p>
            {item.plan !== "Contract Plan" && (
              <p className=" text-nowrap  bg-yellow-00 font-semibold text-xl ">
                {item.min} - {item.max}
              </p>
            )}
            {item.plan === "Contract Plan" && (
              <p className=" text-nowrap  bg-yellow-00 font-semibold text-xl ">
                {item.min} and Above
              </p>
            )}{" "}
            <Button
              onClick={() => {
                navigate(`${routeObj.deposit_type}${item.slug}`);
              }}
              className="  w-full py-3 text-whit bg-[#2C3033] text-white border- flex justify-center items-center gap-3 "
            >
              Continue
            </Button>
            {/* <p className="   bg-yellow-00 font-light text-sm  ">
                For 3 weeks
              </p> */}
            <div className="flex items-center gap-3 text-sm font-light ">
              <FaCircleCheck /> Weekly Profit: {item.weekly}
            </div>
            <div className="flex items-center gap-3 text-sm font-light  ">
              <FaCircleCheck /> Referral Percentage: {item.ref}
            </div>
            <div className="flex items-center gap-3 text-sm font-light  ">
              <FaCircleCheck /> Capital Insurance: Available
            </div>
          </div>
        ))}
        {/* {inv_types?.map((item, index) => (
          <div
            onClick={() => {
              navigate(`${routeObj.deposit_type}${item.plan}`);
            }}
            key={index}
            className=" w-[90%] cursor-pointer mx-auto md:w-[30%] bg-primary rounded-lg shadow-md py-5 pb-8 px-5 "
          >
            <div className=" h-32 ring-1 ring-black/5  rounded-lg bg-white/10 backdrop-blur-md flex justify-center items-center  ">
              <img
                src={item.picture}
                className="w-[100px] h-[100px]  "
                alt=""
              />
            </div>

            <p className="text-center text-white py-4 bg-green-00 font-semibold text-xl ">
              {item.min} - {item.max}
            </p>
            <p className="text-center text-white border-b-2 pb-8 bg-yellow-00 font-semibold text-lg ">
              {item.plan} Plan
            </p>
            <div className=" flex gap-3  w-fit mx-auto m-auto py-3 justify-center md:justify-normal items-center ">
              <div className=" h-2 w-2 rounded-full bg-white "></div>
              <p className=" text-white text-sm font-bold ">{item.ref}</p>
            </div>
            <div className=" flex gap-3 bg-red-00 w-fit mx-auto m-auto justify-center md:justify-normal items-center ">
              <div className=" h-2 w-2 rounded-full bg-white "></div>
              <p className=" text-white text-sm font-bold ">
                Weekly ROI Of {item.weekly}
              </p>
            </div>
            
          </div>
         
        ))} */}
      </div>
    </DashboardLayout>
  );
}
