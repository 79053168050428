import React, { useState } from "react";
import LayoutOne from "../../Layout/Layout_One";
import { accordionData } from "../../Utils/data";
import { AccordionIT } from "./component/AccordionIT";
import { are_you_1, are_you_2 } from "../../Utils/functions";
import { MdCheckCircle } from "react-icons/md";
import { AiFillTags } from "react-icons/ai";
import { authConfig } from "../../Utils/constants";

export default function Faq() {
  const [selected, setSelected] = useState<number | null>(null);

  return (
    <LayoutOne shadow={true}>
      <div className=" pt-[180px]">
        <p className="text-center md:pb-10 text-xl md:text-4xl font-bold text-primary ">
          Frequently Asked Questions?
        </p>
      </div>
      <p className=" w-[90%] md:w-[50%] text-[13px] md:text-[16px] text-center m-auto pt-3 pb-8 md:pb-5 ">
        Everything you need to know, is right here. Can’t find the anwer you are
        looking for? Email us at {"  "}
        <a href={`mailto:${authConfig.email}`}>{authConfig.email}</a>
      </p>
      <div className="flex bg-red-00 px-5 md:px-0 items-center justify-center ">
        <img src="/images/faq.png" alt="" />
      </div>
      <div className=" w-full md:w-[90%] min-h-[200px] m-auto mt-[20px] p-[10px] flex flex-col md:flex-row gap-4 text-[14px] md:text-[18px] ">
        <div className=" text-xl pl-5 md:text-3xl w-full md:w-[35%] font-semibold">
          General FAQs
        </div>
        <div className=" w-full ">
          {accordionData?.map((accord, index) => (
            <AccordionIT
              key={index}
              title={accord?.title}
              desc={accord?.desc}
              isActive={index === selected}
              setSelected={setSelected}
              sectionIndex={index}
            />
          ))}
        </div>
      </div>
      <div
        className="bg-no-repeat bg-cover bg-center  bg-rgba "
        style={{ backgroundImage: `url('/images/strategy2.jpg')` }}
      >
        <div className=" bg-rgba py-[55px] text-[14px] md:text-[18px] flex flex-col ">
          <p className=" text-[16px] md:text-[30px] uppercase w-[78%] m-auto py-[20px] text-white ">
            Help Us Understand Your Financial Landscape
          </p>
          <p className=" text-[13px] md:text-[16px] w-[78%] m-auto text-white ">
            Here at {authConfig.appName}, we believe in personalized financial
            planning that aligns with your unique aspirations and circumstances.
            To ensure we can provide the most tailored advice, we would like to
            understand your financial priorities and life situation. Here are a
            few questions that will help us get to know you better:
          </p>
          <ul className="space-y-4 w-[78%] m-auto py-[20px] text-[#e5e5e5]  ">
            {are_you_1?.map((item, index) => (
              <div
                className="flex items-center gap-3 text-[13px] md:text-[16px] "
                key={index}
              >
                <AiFillTags className="text-[20px]" />
                {item.dsc}
              </div>
            ))}
          </ul>
          <p className=" w-[78%] text-[13px] md:text-[16px] m-auto text-white ">
            We take a grounded approach to the guidance we provide. Our
            recommendations are tailored to fit your unique situation,
            considering factors such as
          </p>
          <ul className="space-y-4 w-[78%] m-auto py-[20px] text-[#e5e5e5]">
            {are_you_2?.map((item, index) => (
              <div
                className="flex items-center gap-3 text-[13px] md:text-[16px] "
                key={`are-${index}`}
              >
                <AiFillTags />
                {item.dsc}
              </div>
            ))}
          </ul>
          <p className=" w-[78%] m-auto text-white text-[13px] md:text-[16px]">
            These questions will help us craft a financial strategy that is as
            unique as you are, ensuring that your financial plan supports your
            life plan.
          </p>
        </div>
      </div>

      <div className=" w-fit px-3 m-auto text-center mt-6 ">
        <p className=" py-3 text-2xl font-bold ">Have any other questions?</p>
        <p className=" text-primary text-[13px] md:text-[16px] ">
          Send us an email with your enquiry and statement at <br />
          <a href={`mailto:${authConfig.email}`}>{authConfig.email}</a>
        </p>
      </div>
    </LayoutOne>
  );
}
