import React from "react";

export default function Strategies() {
  return (
    <div
      className="bg-no-repeat bg-cover bg-center  bg-rgba "
      style={{ backgroundImage: `url('/images/strategy2.jpg')` }}
    >
      <div className=" bg-rgba pt-20 px-5 md:px-20  ">
        {/* <h3 className=" font-bold text-[12px] md:text-sm text-center md:text-center text-white ">
        strategies
        </h3> */}
        <h1 className=" capitalize font-bold text-sm md:text-2xl text-white text-center md:text-center py-5 ">
          Our core strategies for success
        </h1>
        <p className=" text-center text-[12px] md:text-[16px] text-white  m-auto md:w-[90%] ">
          These principles aren’t just statements; they form the foundation of
          every decision we make, every strategy we develop, and every
          partnership we nurture. They drive us to transform your financial
          goals into tangible achievements.
        </p>
        <div className=" md:my-4 w-[97%] md:w-[90%]  justify-between gap-10 flex-wrap m-auto flex py-10 md:py-20  ">
          <div className=" md:w-[45%] bg-blrgb p-5 rounded-md ">
            <div className=" flex gap-4 md:gap-8  md:justify-normal items-center ">
              {/* <img
                src="/images/strategy.svg"
                className=" h-5 md:h-7 w-5 md:w-7 "
                alt=""
              /> */}
              <p className=" font-semibold text-[13px] md:text-[16px] ">
                Diversified Digital Asset Investments
              </p>
            </div>
            <p className=" md:px-16 text-[12px] md:text-[16px] py-3 text-left ">
              We provide access to a wide range of digital assets across
              decentralized finance, Web 3.0, and crypto mining. Our strategic
              portfolio management ensures exposure to high-potential
              opportunities while mitigating risks through diversification.
            </p>
          </div>
          <div className=" bg-blrgb p-5 rounded-md  md:w-[45%] ">
            <div className=" flex gap-4 md:gap-8  md:justify-normal items-center ">
              <p className=" font-semibold text-[13px] md:text-[16px] ">
                ESG focused crypro mining
              </p>
            </div>
            <p className=" md:px-16 text-[12px] md:text-[16px] py-3 text-left ">
              We prioritize sustainability by employing green energy solutions
              in our cryptocurrency mining operations. This aligns with our
              commitment to environmental, social, and governance (ESG)
              principles, making your investments eco-conscious and
              forward-looking.
            </p>
          </div>
          <div className=" bg-blrgb p-5 rounded-md  md:w-[45%] ">
            <div className=" flex gap-4 md:gap-8  md:justify-normal items-center ">
              <p className=" font-semibold text-[13px] md:text-[16px] ">
                Market insight and education{" "}
              </p>
            </div>
            <p className=" md:px-16 text-[12px] md:text-[16px] py-3 text-left ">
              We empower investors with insightful information, analysis, and
              expert judgment on current market trends. Whether you’re a
              seasoned investor or just starting, our resources ensure informed
              and confident decision-making.
            </p>
          </div>
          <div className=" bg-blrgb p-5 rounded-md  md:w-[45%] ">
            <div className=" flex gap-4 md:gap-8  md:justify-normal items-center ">
              <p className=" font-semibold text-[13px] md:text-[16px] ">
                Innovative Financial Technology Development
              </p>
            </div>
            <p className=" md:px-16 text-[12px] md:text-[16px] py-3 text-left ">
              We harness state-of-the-art quantitative models and cutting-edge
              technologies to optimize trading systems. Our commitment to
              innovation delivers world-class investment solutions and sets new
              standards in decentralized finance.
            </p>
          </div>
          <div className="bg-blrgb p-5 rounded-md   md:w-[45%] ">
            <div className=" flex gap-4 md:gap-8  md:justify-normal items-center ">
              <p className=" font-semibold text-[13px] md:text-[16px] ">
                Risk management and security
              </p>
            </div>
            <p className=" md:px-16 text-[12px] md:text-[16px] py-3 text-left ">
              We safeguard your investments with advanced blockchain systems
              that ensure maximum security and trust. Our robust risk management
              framework helps navigate market volatility with precision and
              confidence.
            </p>
          </div>
          <div className=" bg-blrgb p-5 rounded-md  md:w-[45%] ">
            <div className=" flex gap-4 md:gap-8  md:justify-normal items-center ">
              <p className=" font-semibold text-[13px] md:text-[16px] ">
                Shareholder value maximization
              </p>
            </div>
            <p className=" md:px-16 text-[12px] md:text-[16px] py-3 text-left ">
              We focus on generating consistent profits by leveraging
              cryptocurrency market volatility. Our long-term investment
              strategies are designed to maximize returns and create enduring
              value for our shareholders and investors.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
