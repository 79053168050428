import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import DashboardLayout from "../../../Layout/DashBoardLayout";
import { BiArrowBack } from "react-icons/bi";
import PayupModal from "../component/PayupModal";
import { motion } from "framer-motion";

const DropIn = {
  hidden: {
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.75,
      ease: "easeInOut",
      // duration: 0.3,
      // type: "spring",
      // damping: 25,
      // stiffness: 500,
    },
  },
  exit: {
    opacity: 0,
  },
};

export default function DepositType() {
  const navigate = useNavigate();
  const [network, setNetwork] = useState<any>("");
  const [modalOpen, setModalOpen] = useState(false);
  const { type } = useParams<{ type?: string }>();
  const OpenModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  useEffect(() => {
    if (network) {
      OpenModal();
    }
  }, [network]);

  const handleChange = (e: any) => {
    setNetwork(e.target.value);
  };

  return (
    <DashboardLayout>
      {modalOpen && (
        <motion.div
          variants={DropIn}
          initial="hidden"
          animate="visible"
          exit="exit"
          className="absolute top-0 right-0 h-[90vh] dash_webkit overflow-scroll p-2 md:p-10 pb-20 bg-white md:bg-primary "
        >
          <PayupModal
            isOpen={modalOpen}
            closeModal={closeModal}
            plan={type!}
            network={network}
          />
        </motion.div>
      )}
      <div>
        <button
          className=" flex items-center gap-3 text-sm "
          onClick={() => {
            navigate(-1);
          }}
        >
          <BiArrowBack /> Back
        </button>
        <div className=" mt-10 md:mt-20 m-auto w-[100%] md:w-[70%] p-4 bg-white ">
          <p className=" text-[25px] pb-2 font-[600] ">Select Network</p>
          <i className="text-[12px] md:text-[16px] ">
            Choose Crypto Network To Make Payment With
          </i>
          <div className=" text-[12px] md:text-[16px]  flex flex-wrap gap-6 justify-between  pt-5   ">
            <label className="flex flex-col bg-slate-200 w-[100%] md:w-[30%] p-4 rounded-md items-center gap-0 ">
              <div className="flex border-b-0 border-[#d8dbe7] items-center gap-3 h-[50px] justify-between w-full bg-red-00 ">
                <div className="flex items-center bg-green-00  gap-3">
                  {" "}
                  <img
                    loading="lazy"
                    alt=""
                    src="/images/bitcoin.svg"
                    className="h-8 w-8 "
                  />{" "}
                  Bitcoin{" "}
                </div>
                <input
                  value="Bitcoin"
                  type="radio"
                  className=" accent-black "
                  checked={network === "Bitcoin"}
                  onChange={handleChange}
                />
              </div>
              {/* <p className="">Make Your Investment deposit using our bitcoin walle</p> */}
            </label>
            <label className="flex bg-slate-200 w-[100%] md:w-[30%] p-4 rounded-md items-center gap-7 ">
              <div className="flex items-center gap-3 h-[50px] justify-between w-full bg-red-00 ">
                <div className="flex items-center gap-3">
                  {" "}
                  <img
                    loading="lazy"
                    alt=""
                    src="/images/ether.svg"
                    className="h-8 w-8 "
                  />{" "}
                  Ethereum Main Network{" "}
                </div>
              </div>{" "}
              <input
                value="Ethereum"
                type="radio"
                className=" accent-black "
                checked={network === "Ethereum"}
                onChange={handleChange}
              />
            </label>
            {/* <label className="flex bg-slate-200 w-[100%] md:w-[30%] p-4 rounded-md items-center gap-7 ">
              <div className="flex items-center gap-3 h-[50px] justify-between w-full bg-red-00 ">
                <div className="flex items-center gap-3 ">
                  <img
                    loading="lazy"
                    alt=""
                    src="/images/xrp.svg"
                    className="h-8 w-8 "
                  />{" "}
                  XRP
                </div>
              </div>{" "}
              <input
                value="xrp"
                checked={network === "xrp"}
                type="radio"
                className=" accent-black "
                onChange={handleChange}
              />
            </label> */}
            <label className="flex bg-slate-200 w-[100%] md:w-[30%] p-4 rounded-md items-center gap-7 ">
              <div className="flex items-center gap-3 h-[50px] justify-between w-full bg-red-00 ">
                <div className="flex gap-2 items-center">
                  {" "}
                  <img
                    loading="lazy"
                    alt=""
                    src="/images/tether.svg"
                    className="h-8 w-8 "
                  />{" "}
                  Tether USD
                </div>
              </div>{" "}
              <input
                value="usdt"
                checked={network === "usdt"}
                type="radio"
                className=" accent-black "
                onChange={handleChange}
              />
            </label>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}
