import { memo, useEffect, useState } from "react";

import { FaCheckCircle, FaExternalLinkAlt, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";

import { PulseLoader, ScaleLoader } from "react-spinners";
import toast from "react-hot-toast";
import { AppDispatch } from "../../../store";
import { SelectAdmin } from "../../../store/slice/admin";
import DashboardLayout from "../../../Layout/DashBoardLayout";
import ModalP from "../../../components/ModalP";
import { Fmtd_Date, isValidEmail } from "../../../Utils/functions";
import Pagination from "../../../components/Pagination";
import { approveKyc, getKyc } from "../../../store/service/adminApi";
import { BsDot } from "react-icons/bs";

export default function AllKyc() {
  const dispatch = useDispatch<AppDispatch>();
  const { kyc, loading, pagination } = useSelector(SelectAdmin);
  const [isLoading, setIsLoading] = useState(false);
  const FetchData = async (params: { page: number } | null) => {
    try {
      await dispatch(getKyc(params));
    } catch (error) {
      console.log(error);
    }
  };

  const handleAction = async (params: Action) => {
    try {
      setIsLoading(true);
      const req = await approveKyc(params);
      toast.success(req.message);
      await FetchData(null);
      setIsLoading(false);
    } catch (error) {
      toast.error("Approval Failed");
      setIsLoading(false);
    }
  };

  const sendRequest = (val: number) => {
    return FetchData({ page: val });
  };

  useEffect(() => {
    FetchData(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <DashboardLayout>
      <div className=" relative  min-w-0 break-words w-full  ">
        <ModalP
          hasCancelButton={false}
          modalOpen={isLoading}
          handleClose={() => {}}
        >
          <div className=" px-10 py-4 ">
            <PulseLoader />
          </div>
        </ModalP>
        <p className=" text-[20px] md:text-[25px] font-[700] ">KYCs</p>
        <div className="dash_webkit block pt-10 w-full overflow-x-auto ">
          <table className=" bg-red-00 w-full md:w-full ">
            <thead className=" mb-7 ">
              <tr className=" text-left  border-b-2  border-[#d7d7d7] ">
                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  User
                </th>
                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  Name
                </th>
                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  Front View
                </th>
                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  Back View
                </th>
                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  Status
                </th>
                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  Date
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-[#d7d7d7]">
              {!loading &&
                kyc.length !== 0 &&
                kyc?.map((kyc) => (
                  <TableRow
                    key={kyc?.id}
                    id={kyc?.id}
                    fullname={kyc?.fullname! ? kyc.fullname : kyc?.user_id}
                    name={kyc?.name}
                    front_view={kyc?.front_view}
                    back_view={kyc?.back_view!}
                    status={kyc?.status}
                    date={Fmtd_Date(kyc?.created_at)}
                    onClick={(params: Action) => handleAction(params)}
                  />
                ))}

              {!loading && kyc.length === 0 && (
                <tr>
                  <td className="py-0 text-center " colSpan={6}>
                    <div className="w-full py-10 font-bold bg-main text-white text-[20px] flex justify-center items-center ">
                      You Don't Have Any Record
                    </div>
                  </td>
                </tr>
              )}

              {loading && (
                <tr>
                  <td className="py-6 text-center " colSpan={6}>
                    <div className="w-full py-1 bg-red-00 flex justify-center items-center ">
                      <ScaleLoader />
                    </div>
                  </td>
                </tr>
              )}

              <tr>
                <td className="pt-6" colSpan={6}>
                  <Pagination
                    currentPage={pagination?.current}
                    totalPages={pagination?.total}
                    sendRequest={sendRequest}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </DashboardLayout>
  );
}

const TableRow = memo(
  ({
    fullname,
    name,
    front_view,
    back_view,
    status,
    date,
    onClick,
    id,
  }: {
    id: string;
    fullname: string;
    back_view: string;
    front_view: string;
    date: string;
    name: string;
    status: string;
    onClick: (params: Action) => void;
  }) => {
    return (
      <tr className={`text-left border-b-2  border-[#d7d7d7]`}>
        <td className="py-5 whitespace-nowrap px-3">{fullname}</td>
        <td className="py-5 whitespace-nowrap px-3">{name}</td>
        <td className="py-5 whitespace-nowrap px-3">
          {front_view ? (
            <Link to={front_view} target="_blank">
              <div className="flex text-blue-600  items-center gap-2 ">
                View Image <FaExternalLinkAlt />
              </div>
            </Link>
          ) : (
            <>No Image</>
          )}
        </td>
        <td className="py-5 whitespace-nowrap px-3">
          {back_view ? (
            <Link to={back_view} target="_blank">
              <div className="flex text-blue-600  items-center gap-2 ">
                View Image <FaExternalLinkAlt />
              </div>
            </Link>
          ) : (
            <>No Image</>
          )}
        </td>
        <td
          className={`py-5 whitespace-nowrap px-3 ${
            status === "ACTIVE" ? "bg-green-00/25" : ""
          } `}
        >
          <div
            className={` flex items-center gap-1 rounded-lg w-fit px-3 text-[13px] py-1 lowercase ${
              status === "INACTIVE"
                ? "bg-yellow-300/30 text-yellow-900  "
                : "bg-green-300/30 text-green-900 "
            } `}
          >
            {" "}
            <BsDot /> {status}
          </div>
        </td>
        <td className="py-5 whitespace-nowrap px-3">{date}</td>
        <td className="py-5 whitespace-nowrap px-3">
          <div className="flex gap-3 items-center ">
            {status === "ACTIVE" || isValidEmail(fullname) ? (
              ""
            ) : (
              <button
                className="bg-slate-200 border-2 border-green-500 p-3 rounded-full  "
                title="Approve Deposit"
                onClick={() => onClick({ id, action: "APPROVE" })}
              >
                <FaCheckCircle fill="green" />
              </button>
            )}
            {/* <button
              className="bg-slate-200 border-2 border-red-500 p-3 rounded-full"
              title="Reject Deposit"
              onClick={() => onClick({ id, action: "DECLINE" })}
            >
              <FaTimes fill="red" />
            </button> */}
          </div>
        </td>
      </tr>
    );
  }
);
