import { useEffect, useState } from "react";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";

export function PdfCom({ file }: { file: string }) {
  useEffect(() => {
    if (typeof window !== "undefined") {
      pdfjs.GlobalWorkerOptions.workerSrc = new URL(
        "pdfjs-dist/build/pdf.worker.min.mjs",
        import.meta.url
      ).toString();
    }
  }, []);

  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  return (
    <div
      onScroll={(e) => {
        e.stopPropagation();
      }}
      className=" h-[90vh] flex justify-between items-center w-[100%] mt-[180px] px-3 md:px-10 pt-3 md:pt-6 mb-0 bg-[#dedede]  "
    >
      <div className="">
        {numPages && numPages > 1 && (
          <FaArrowLeftLong
            className="cursor-pointer"
            onClick={() => {
              if (pageNumber === 1) {
                return;
              }
              setPageNumber((prev) => (prev -= 1));
            }}
          />
        )}
      </div>
      <div className=" h-full bg-[#dedede  overflow-scroll no_scrollbar  ">
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
          <Page
            renderTextLayer={false}
            renderAnnotationLayer={false}
            pageNumber={pageNumber}
          />
        </Document>
        <p className=" text-center text-sm ">
          Page {pageNumber} of {numPages}
        </p>
      </div>
      <div className="">
        {numPages && numPages > 1 && (
          <FaArrowRightLong
            className=" cursor-pointer "
            onClick={() => {
              if (pageNumber === numPages) {
                return;
              }
              setPageNumber((prev) => (prev += 1));
            }}
          />
        )}
      </div>
    </div>
  );
}
