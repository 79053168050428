import { useFormik } from "formik";
import toast from "react-hot-toast";
import { authConfig } from "../Utils/constants";
import { useDispatch } from "react-redux";
import {
  initialForgot,
  initialLogin,
  initialRegister,
  initialReset,
} from "../Formik/InitialValues/auth";
import {
  forgotSchema,
  loginSchema,
  registerSchema,
  resetSchema,
} from "../Formik/Validations/auth";
import { useNavigate } from "react-router-dom";
import { routeObj } from "../constants/routes";
import { AppDispatch } from "../store";
import {
  forgotPassword,
  logout,
  registeration,
  resetPassword,
  login as UsersLogin,
} from "../store/service/auth";
import { useState } from "react";
// import { AppDispatch } from "../";

export default function useAuthHooks() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const login = useFormik({
    initialValues: initialLogin,
    validationSchema: loginSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      try {
        const { data } = await dispatch(UsersLogin(values)).unwrap();
        if (data?.user?.type === "ADMIN") {
          return navigate(routeObj.admins_dashboard);
        }
        navigate(routeObj.users_dashboard);
        return toast.success("Login Successful");
      } catch (error) {
        return toast.error(error as string);
      }
    },
  });

  const register = useFormik({
    initialValues: initialRegister,
    validationSchema: registerSchema,
    onSubmit: async (values) => {
      try {
        const { message } = await dispatch(registeration(values)).unwrap();
        toast.success(message);
        navigate(routeObj.login);
      } catch (error) {
        console.log(error);
        toast.error(error as string);
      }
    },
  });

  const reset = useFormik({
    initialValues: initialReset,
    validationSchema: resetSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const { message } = await dispatch(resetPassword(values)).unwrap();
        navigate(routeObj.login);
        toast.success(message);
      } catch (error) {
        console.log(error);
        toast.error(error as string);
      }
    },
  });

  const forgot = useFormik({
    initialValues: initialForgot,
    validationSchema: forgotSchema,
    onSubmit: async (values) => {
      try {
        const { message } = await dispatch(forgotPassword(values)).unwrap();
        toast.success(message);
      } catch (error) {
        console.log(error);
        toast.error(error as string);
      }
    },
  });

  const Logout = async () => {
    try {
      setLoading(true);
      const { message } = await dispatch(logout()).unwrap();
      toast.success(message);
      dispatch({ type: "auth/logout" });
      navigate(routeObj.login);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return { login, register, reset, forgot, Logout, loading };
}
