import "swiper/css";
import { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  EffectFade,
  Autoplay,
  Scrollbar,
  A11y,
  Pagination,
} from "swiper/modules";

import "swiper/css/navigation";
import "swiper/css/effect-fade";
import { Link } from "react-router-dom";
import { routeObj } from "../../../constants/routes";
import { authConfig } from "../../../Utils/constants";

export default function Hero() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="bg-black  hero_view_height relative  md:h-[85vh]">
      <Swiper
        modules={[
          Navigation,
          EffectFade,
          Pagination,
          Scrollbar,
          A11y,
          Autoplay,
        ]}
        navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        speed={1000}
        spaceBetween={800}
        slidesPerView={1}
        loop
        autoplay={{
          delay: 7000,
          disableOnInteraction: false,
        }}
      >
        <SwiperSlide>
          <div
            className="  hero_view_height relative  md:h-[85vh] bg-center bg-no-repeat bg-cover "
            style={{ backgroundImage: `url('/images/hero1.jpg')` }}
          >
            <div className=" bg-rgba/15 hero_view_height relative  md:h-[85vh] ">
              <div className="  text-center flex justify-center items-center flex-col pt-[150px] md:pt-[200px] w-full text-white bg-green-00 ">
                <h1 className=" md:w-[50%] uppercase m-auto text-xl md:text-4xl  px-4 text-center font-semibold  md:leading-[50px] ">
                  Shape your financial future with {authConfig.appName} today
                </h1>
                <p className=" md:w-[60%] pt-6 pb-4 text-[12px] md:text-[15px] px-3 font-medium ">
                  Benefit from the future technologies of decentralized finance,
                  cryptocurrency and blockchain.
                </p>
                <div className="flex mt-4 gap-4 bg-red items-center ">
                  <Link to={routeObj.whitepaper}>
                    <button className=" border-white px-6 md:px-10 py-3 text-sm md:text-[16px] border-2 rounded-lg font-semibold  ">
                      View white paper
                    </button>
                  </Link>
                  <Link to={routeObj.register}>
                    {" "}
                    <button className="bg-primary text-white font-semibold text-sm md:text-[16px] px-6 md:px-10 py-4 border- rounded-lg ">
                      Sign Up Now
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div
            className="  hero_view_height relative  md:h-[85vh] bg-center bg-no-repeat bg-cover "
            style={{ backgroundImage: `url('/images/hero2.png')` }}
          >
            <div className=" bg-rgba/35 hero_view_height relative  md:h-[85vh] ">
              <div className="  text-center flex justify-center items-center flex-col pt-[150px] md:pt-[200px] w-full text-white bg-green-00 ">
                <h1 className=" md:w-[50%] uppercase m-auto text-xl md:text-4xl  px-4 text-center font-semibold  md:leading-[50px] ">
                  Shape your financial future with {authConfig.appName} today
                </h1>
                <p className=" md:w-[60%] pt-6 pb-4 text-[12px] md:text-[15px] px-3 font-medium ">
                  Benefit from the future technologies of decentralized finance,
                  cryptocurrency and blockchain.
                </p>
                <div className="flex mt-4 gap-4 bg-red items-center ">
                  <Link to={routeObj.whitepaper}>
                    <button className=" border-white px-6 md:px-10 py-3 text-sm md:text-[16px] border-2 rounded-lg font-semibold  ">
                      View white paper
                    </button>
                  </Link>
                  <Link to={routeObj.register}>
                    {" "}
                    <button className="bg-primary text-white font-semibold text-sm md:text-[16px] px-6 md:px-10 py-4 border- rounded-lg ">
                      Sign Up Now
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div
            className="  hero_view_height relative  md:h-[85vh] bg-center bg-no-repeat bg-cover "
            style={{ backgroundImage: `url('/images/hero4.png')` }}
          >
            <div className=" bg-rgba/55 hero_view_height relative  md:h-[85vh] ">
              <div className="  text-center flex justify-center items-center flex-col pt-[150px] md:pt-[200px] w-full text-white bg-green-00 ">
                <h1 className=" md:w-[50%] uppercase m-auto text-xl md:text-4xl  px-4 text-center font-semibold  md:leading-[50px] ">
                  Shape your financial future with {authConfig.appName} today
                </h1>
                <p className=" md:w-[60%] pt-6 pb-4 text-[12px] md:text-[15px] px-3 font-medium ">
                  Benefit from the future technologies of decentralized finance,
                  cryptocurrency and blockchain.
                </p>
                <div className="flex mt-4 gap-4 bg-red items-center ">
                  <Link to={routeObj.whitepaper}>
                    <button className=" border-white px-6 md:px-10 py-3 text-sm md:text-[16px] border-2 rounded-lg font-semibold  ">
                      View white paper
                    </button>
                  </Link>
                  <Link to={routeObj.register}>
                    {" "}
                    <button className="bg-primary text-white font-semibold text-sm md:text-[16px] px-6 md:px-10 py-4 border- rounded-lg ">
                      Sign Up Now
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
