export default function Research() {
  return (
    <div className="bg-green-00 -top-[60px] md:-top-[120px] z-[2] relative">
      <div className="  bg-gray-00 ">
        <div className=" bg-red-00   w-full m-auto  ">
          <div className=" m-auto w-[95%] md:w-[70%] flex-wrap md:flex-row  py-5 flex justify-around md:justify-between gap-4 ">
            <div className=" w-[47%] md:w-[30%] bg-hexWhite shadow-xl rounded-lg px-2 md:px-5 py-10 ">
              <div>
                <img
                  className="h-[30px] w-[30px]  "
                  src="/images/prt1.gif"
                  alt=""
                />
              </div>
              <p className=" font-bold text-sm md:text-lg py-4 ">
                Data-Driven Investment Solutions
              </p>
              <p className="  text-[10px] md:text-sm md:w-[80%] ">
                We meticulously analyze diverse sectors and asset classes to
                identify top-tier investment opportunities for our clients.
              </p>
            </div>
            <div className=" w-[47%] hidden md:block md:w-[30%] bg-primary text-white shadow-xl rounded-lg px-3 md:px-5 py-10 ">
              <div>
                <img
                  className="h-[30px] w-[30px]  "
                  src="/images/briefcase.gif"
                  alt=""
                />
              </div>
              <p className=" font-bold text-sm md:text-lg py-4 ">
                Proactive Portfolio <br /> Oversight
              </p>
              <p className=" text-[10px] md:text-sm md:w-[80%] ">
                We actively review and refine your investments to align with
                market trends and your evolving financial aspirations.
              </p>
            </div>
            <div className=" w-[47%] md:w-[30%] bg-hexWhite shadow-xl rounded-lg px-3 md:px-5 py-10 ">
              <div>
                <img
                  className="h-[30px] w-[30px]  "
                  src="/images/target.gif"
                  alt=""
                />
              </div>
              <p className=" font-bold text-sm md:text-lg py-4 ">
                Personalized Financial Planning
              </p>
              <p className=" text-[10px] md:text-sm md:w-[80%] ">
                We start by clarifying your financial objectives, whether it's
                growth, income, or preservation, to construct a customized
                investment plan.
              </p>
            </div>
            <div className=" w-[47%] block md:hidden md:w-[30%] bg-primary text-white shadow-xl rounded-lg px-3 md:px-5  py-10 ">
              <div>
                <img
                  className="h-[30px] w-[30px]  "
                  src="/images/briefcase.gif"
                  alt=""
                />
              </div>
              <p className=" font-bold text-sm md:text-lg py-4 ">
                Proactive Portfolio <br /> Oversight
              </p>
              <p className=" text-[10px] md:text-sm md:w-[80%] ">
                We actively review and refine your investments to align with
                market trends and your evolving financial aspirations.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
