import React from "react";
import LayoutOne from "../../Layout/Layout_One";
import Testimonials from "./component/Testimonials";
import Insight from "./component/Insights";

export default function Service() {
  return (
    <LayoutOne>
      <div
        className=" h-[45vh] md:h-[60vh] bg-center bg-cover text-center bg-primary text-white  "
        style={{ backgroundImage: `url('/images/serve.jpg')` }}
      >
        <div className="h-[45vh] md:h-[60vh] bg-rgba flex-col flex justify-center items-center">
          <h1 className=" w-[90%] md:w-[55%] leading-[35px] md:leading-[70px] px- md:px-16 font-bold text-xl md:text-5xl ">
            Discover all your investment solutions in one place!{" "}
          </h1>
          <p className=" w-[95%] md:w-[50%] px-3 text-[12px] font-light md:text-lg pt-5 md:pt-8  ">
            Whether you're just starting out in the world of finance or aiming
            to optimize a complex portfolio, our services are tailored to meet
            you at your current stage and help you progress. Our team of expert
            advisors uses advanced technology alongside proven strategies to
            create personalized solutions that match your specific goals and
            risk tolerance.
          </p>
        </div>
      </div>
      <div className=" pt-10 md:pt-20  ">
        <h1 className=" hidden md:block font-bold text-2xl text-center py-5 pb-0 ">
          Our Services
        </h1>
        <div
          className={` pt-[20px]  flex justify-center items-center flex-col `}
        >
          <p
            className={` text-center text-[13px] md:text-[16px]  w-[90%] md:w-[80%] `}
          >
            At Quotrack, we take pride in our groundbreaking "QuantumPredict"
            algorithm, an exclusive technology that forecasts market trends with
            remarkable precision. Secure premium entry to this robust tool,
            providing you with a unique edge in the investment realm. Our
            algorithm ensures steady profits and enables you to surpass the
            market with ease.
          </p>

          <div className=" h-[200px] md:h-[300px] pt-[50px] w-[90%] md:w-[80%] flex justify-between ">
            <div className=" min-h-[90%] w-[30%] md:w-[15%] flex flex-col items-center ">
              <div className=" h-[50px] w-[50px] border-4 border-[#06274e] rounded-full flex justify-center items-center ">
                <img
                  className="w-[50%]"
                  loading="lazy"
                  src="/images/ico1.png"
                  alt="Guaranteed"
                />
              </div>
              <p className={`text-sub_main text-[13px] md:text-[16px]  `}>
                <b>Assured </b>
              </p>
              <p className={` text-[12px] md:text-[15px] `}>Profits</p>
            </div>
            <div className=" min-h-[90%] w-[15%] hidden md:flex flex-col items-center ">
              <div className=" h-[50px] w-[50px] border-4 border-[#06274e] rounded-full flex justify-center items-center ">
                <img
                  className="w-[50%]"
                  loading="lazy"
                  src="/images/ico4.png"
                  alt="Pre-IPO"
                />
              </div>
              <p className={`text-sub_main text-[13px] md:text-[16px]  `}>
                <b>Early-Stage </b>
              </p>
              <p className={` text-[12px] md:text-[15px] `}>Investments</p>
            </div>
            <div className=" min-h-[90%] hidden m w-[15%] md:flex flex-col items-center ">
              <div className=" h-[50px] w-[50px] border-4 border-[#06274e] rounded-full flex justify-center items-center ">
                <img
                  className="w-[50%]"
                  loading="lazy"
                  src="/images/ico5.png"
                  alt="Zero-Risk"
                />
              </div>
              <p className={`text-sub_main text-[13px] md:text-[16px]  `}>
                <b>Risk-Free</b>
              </p>
              <p className={` text-[12px] md:text-[15px] `}>Investment</p>
            </div>
            <div className=" min-h-[90%] w-[30%] md:w-[15%] flex flex-col items-center ">
              <div className=" h-[50px] w-[50px] border-4 border-[#06274e] rounded-full flex justify-center items-center ">
                <img
                  className="w-[50%]"
                  loading="lazy"
                  src="/images/ico3.png"
                  alt="Exclusive"
                />
              </div>
              <p className={`text-sub_main text-[13px] md:text-[16px]  `}>
                <b>Premium</b>
              </p>
              <p className={` text-[12px] md:text-[15px] `}>Entry</p>
            </div>
            <div className=" min-h-[90%] w-[30%] md:w-[15%] flex flex-col items-center ">
              <div className="h-[50px] w-[50px] border-4 border-[#06274e] rounded-full flex justify-center items-center ">
                <img
                  className="w-[50%]"
                  loading="lazy"
                  src="/images/ico2.png"
                  alt="Top-Tier"
                />
              </div>
              <p className={`text-sub_main text-[13px] md:text-[16px]  `}>
                <b>Elite</b>
              </p>
              <p className={` text-[12px] md:text-[15px] `}>Consultants</p>
            </div>
          </div>
        </div>

        <div className=" flex justify-between flex-wrap gap-11 px-5 md:px-20 ">
          <div className="bg-white md:w-[45%] pb-10 overflow-hidden  ">
            <div className=" w-[50%] m-auto  bg-red-00 ">
              <img
                src="/images/tax.png"
                alt=""
                className="  relative -top-2 "
              />{" "}
            </div>
            <p className=" text-center py-4 font-bold ">
              Tax Planning and Compliance
            </p>
            <p className=" px-5 md:px-20 text-center text-[13px] md:text-[16px] ">
              Our tax experts simplify complex financial matters to improve your
              efficiency. We stay updated on changing regulations and find
              opportunities to reduce your tax burden. With our strategic
              approach, you can navigate tax season confidently, knowing that
              we've explored every way to save and ensure your compliance.
            </p>
          </div>
          <div className="bg-white md:w-[45%] pb-10 overflow-hidden  ">
            <div className=" w-[50%] m-auto  bg-red-00 ">
              <img
                src="/images/fund.png"
                alt=""
                className=" relative -top-2 "
              />
            </div>
            <p className=" text-center py-4 font-bold ">
              Hedge fund Management{" "}
            </p>
            <p className=" px-5 md:px-20 text-center text-[13px] md:text-[16px] ">
              Our expert team uses advanced analytics to analyze markets and
              create high-potential portfolios. We balance aggressive
              opportunity-seeking with robust risk management to aim for returns
              that surpass conventional investment strategies. Quotrack
              institutional-grade investment strategies, tailored for discerning
              investors looking to enhance their market performance.
            </p>
          </div>{" "}
          <div className="bg-white md:w-[45%] pb-10 overflow-hidden  ">
            <div className=" w-[50%] m-auto  bg-red-00 ">
              <img
                src="/images/wealth.png"
                alt=""
                className=" relative -top-2 "
              />
            </div>
            <p className=" text-center py-4 font-bold ">Wealth Management </p>
            <p className=" px-5 md:px-20 text-center text-[13px] md:text-[16px] ">
              Here at Quotrack, we do more than just manage wealth; we nurture
              it. Our experienced advisors create a personalized financial plan
              tailored to your specific needs, aimed at protecting and growing
              your assets. We handle strategic asset allocation, detailed risk
              management, tax optimization, and legacy planning, overseeing
              every aspect of your financial well-being
            </p>
          </div>{" "}
          <div className="bg-white md:w-[45%] pb-10 overflow-hidden  ">
            <div className=" w-[50%] m-auto  bg-red-00 ">
              <img
                src="/images/hedge.png"
                alt=""
                className="  relative -top-2 "
              />
            </div>
            <p className=" text-center py-4 font-bold ">
              Portfolio Management{" "}
            </p>
            <p className=" px-5 md:px-20 text-center text-[13px] md:text-[16px] ">
              Trust our experienced professionals to guide your financial
              future. We will create and manage a customized investment
              portfolio that matches your risk tolerance, goals, and timeline.
              Benefit from our expertise in strategic asset allocation and
              diversification to potentially maximize your returns while you
              focus on your priorities.to maximize your returns potentially—all
              while you focus on what matters most to you
            </p>
          </div>{" "}
          <div className="bg-white md:w-[45%] pb-10 overflow-hidden  ">
            <div className=" w-[50%] m-auto  bg-red-00 ">
              <img
                src="/images/retire.png"
                alt=""
                className=" relative -top-2 "
              />
            </div>
            <p className=" text-center py-4 font-bold ">Retirement Planning </p>
            <p className=" px-5 md:px-20 text-center text-[13px] md:text-[16px] ">
              We turn retirement dreams into actionable strategies. Our
              experienced advisors analyze your unique situation, optimize
              assets and income streams, and design a strong financial roadmap.
              From smart account management to strategic withdrawals, we will
              guide you toward a retirement that is not only comfortable but
              also truly fulfilling.
            </p>
          </div>{" "}
          <div className="bg-white md:w-[45%] pb-10 overflow-hidden  ">
            <div className=" w-[50%] m-auto  bg-red-00 ">
              <img
                src="/images/settings.png"
                alt=""
                className=" relative -top-2 "
              />
            </div>
            <p className=" text-center py-4 font-bold ">
              Financial Goal setting
            </p>
            <p className=" px-5 md:px-20 text-center text-[13px] md:text-[16px] ">
              Turn your dreams into tangible financial goals with our
              goal-setting tools. Set personalized timelines and strategies to
              achieve short and long-term objectives, from owning a home to
              retiring. Monitor your progress, make adjustments for life
              changes, and stay motivated as you see your financial dreams
              become a reality
            </p>
          </div>
          <div className="bg-white md:w-[45%] pb-10 overflow-hidden  ">
            <div className=" w-[50%] m-auto  bg-red-00 ">
              <img
                src="/images/calc.png"
                alt=""
                className=" relative -top-2 "
              />
            </div>
            <p className=" text-center py-4 font-bold ">
              Investment Calculator{" "}
            </p>
            <p className=" px-5 md:px-20 text-center text-[13px] md:text-[16px] ">
              Forecast your financial future using our comprehensive investment
              planning tool. Calculate potential returns, project retirement
              savings, and explore various investment scenarios. Make informed
              decisions quickly with easy-to-understand projections tailored to
              your goals.
            </p>
          </div>{" "}
          <div className="bg-white md:w-[45%] pb-10 overflow-hidden  ">
            <div className=" w-[50%] m-auto  bg-red-00 ">
              <img
                src="/images/portfolio.png"
                alt=""
                className=" relative -top-2 "
              />
            </div>
            <p className=" text-center py-4 font-bold ">Estate Planning </p>
            <p className=" px-5 md:px-20 text-center text-[13px] md:text-[16px] ">
              Preserving and transferring your wealth to future generations
              necessitates meticulous estate planning. Our comprehensive estate
              planning services aid in formulating a robust strategy to
              safeguard your assets, mitigate estate taxes, and uphold your
              legacy in accordance with your stipulations.
            </p>
          </div>{" "}
        </div>
        <div className="px-0 pt-10 md:px-0">
          <Insight />
        </div>

        {/* <div className="pt-10">
          <Testimonials />
        </div> */}
      </div>
    </LayoutOne>
  );
}
