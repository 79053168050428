import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  EffectFade,
  Autoplay,
  Scrollbar,
  A11y,
  Pagination,
} from "swiper/modules";
import { authConfig } from "../../../Utils/constants";
export default function Testimonials() {
  return (
    <div
      className="bg-no-repeat bg-cover bg-center "
      style={{ backgroundImage: `url('/images/strategy2.jpg')` }}
    >
      <div className=" bg-rgba ">
        <div className=" py-6 pt-10  md:py-10 bg-rgba text-white ">
          <h3 className=" font-bold text-[12px] md:text-sm text-center md:text-center text-white ">
            TESTIMONIALS
          </h3>
          <h1 className=" upp font-bold text-sm md:text-2xl text-center md:text-center py-5 ">
            What our investors say about us
          </h1>
          <div className=" w-full pt-[30px] ">
            <Swiper
              modules={[
                Navigation,
                EffectFade,
                Pagination,
                Scrollbar,
                A11y,
                Autoplay,
              ]}
              navigation
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
              speed={1000}
              spaceBetween={800}
              slidesPerView={1}
              loop
              autoplay={{
                delay: 7000,
                disableOnInteraction: false,
              }}
            >
              <SwiperSlide>
                <div className="flex min-h-[0vh]  gap-4 md:min-h-[0vh] justify-center bg-red-00 w-full ">
                  <div className="">
                    <img
                      className=" h-[30px] w-[30px] md:h-[50px] md:w-[50px] "
                      src="/images/comma.svg"
                      alt=""
                    />
                  </div>
                  <div className=" pt-[30px] pb-[10px] md:py-[30px]  text-[12px]  md:text-[15px] w-[80%]  md:w-[60%]">
                    <p className=" md:pt-5 text-left ">
                      I never imagined how much my approach to investing could
                      improve until I tried this platform. The tools and
                      insights provided gave me a new level of confidence. In
                      just a few months, I was making smarter, more strategic
                      moves with my portfolio. The results have been
                      game-changing, and I’m now closer to achieving my
                      financial goals than ever before.
                    </p>
                    <p className=" pt-[30px] pb-[10px] md:py-[30px]  text-center ">
                      - Victor Lane.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="flex min-h-[0vh]  gap-4 md:min-h-[0vh] justify-center bg-red-00 w-full ">
                  <div className="">
                    <img
                      className=" h-[30px] w-[30px] md:h-[50px] md:w-[50px] "
                      src="/images/comma.svg"
                      alt=""
                    />
                  </div>
                  <div className=" pt-[30px] pb-[10px] md:py-[30px]  text-[12px]  md:text-[15px] w-[80%]  md:w-[60%]">
                    <p className=" md:pt-5 text-left ">
                      Choosing {authConfig.appName} turned out to be a
                      game-changer for me. The platform’s innovative features
                      and expert support made all the difference. My portfolio’s
                      growth has exceeded my expectations, and I couldn’t be
                      happier. I wholeheartedly recommend it to anyone looking
                      to take control of their financial journey.
                    </p>
                    <p className=" pt-[30px] pb-[10px] md:py-[30px]  text-center ">
                      -Sophia Grace
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="flex min-h-[0vh]  gap-4 md:min-h-[0vh] justify-center bg-red-00 w-full ">
                  <div className="">
                    <img
                      className=" h-[30px] w-[30px] md:h-[50px] md:w-[50px] "
                      src="/images/comma.svg"
                      alt=""
                    />
                  </div>
                  <div className=" pt-[30px] pb-[10px] md:py-[30px]  text-[12px]  md:text-[15px] w-[80%]  md:w-[60%]">
                    <p className=" md:pt-5 text-left ">
                      Choosing {authConfig.appName} has been one of the smartest
                      financial moves I’ve made. From day one, the platform’s
                      seamless experience and advanced tools have stood out. The
                      remarkable growth of my portfolio reflects the
                      effectiveness of their strategies and commitment to
                      delivering results. I can confidently recommend{" "}
                      {authConfig.appName} to anyone ready to elevate their
                      financial planning and achieve their goals.
                    </p>
                    <p className=" pt-[30px] pb-[10px] md:py-[30px]  text-center ">
                      -Laura Hayes
                    </p>
                  </div>
                </div>
              </SwiperSlide>{" "}
              <SwiperSlide>
                <div className="flex min-h-[0vh]  gap-4 md:min-h-[0vh] justify-center bg-red-00 w-full ">
                  <div className="">
                    <img
                      className=" h-[30px] w-[30px] md:h-[50px] md:w-[50px] "
                      src="/images/comma.svg"
                      alt=""
                    />
                  </div>
                  <div className=" pt-[30px] pb-[10px] md:py-[30px]  text-[12px]  md:text-[15px] w-[80%]  md:w-[60%]">
                    <p className=" md:pt-5 text-left ">
                      {authConfig.appName} has gone above and beyond my
                      expectations. Their expertise and attention to detail are
                      matched by their commitment to client success. The
                      platform combines outstanding service with consistently
                      impressive results, making it a standout choice for anyone
                      serious about growing their investments. I fully trust{" "}
                      {authConfig.appName} and highly recommend it to those
                      seeking a dependable and effective financial partner.
                    </p>
                    <p className=" pt-[30px] pb-[10px] md:py-[30px]  text-center ">
                      -Ethan Cole
                    </p>
                  </div>
                </div>
              </SwiperSlide>{" "}
              <SwiperSlide>
                <div className="flex min-h-[0vh]  gap-4 md:min-h-[0vh] justify-center bg-red-00 w-full ">
                  <div className="">
                    <img
                      className=" h-[30px] w-[30px] md:h-[50px] md:w-[50px] "
                      src="/images/comma.svg"
                      alt=""
                    />
                  </div>
                  <div className=" pt-[30px] pb-[10px] md:py-[30px]  text-[12px]  md:text-[15px] w-[80%]  md:w-[60%]">
                    <p className=" md:pt-5 text-left ">
                      {authConfig.appName} has completely redefined my
                      investment journey. With their expert guidance and
                      personalized strategies, I’ve built a profitable and
                      well-balanced portfolio that perfectly aligns with my
                      financial objectives. Their unwavering dedication to
                      client success and deep market insights make them a
                      standout in the industry. I confidently recommend{" "}
                      {authConfig.appName} to anyone looking for a trustworthy
                      and results-driven investment partner.
                    </p>
                    <p className=" pt-[30px] pb-[10px] md:py-[30px]  text-center ">
                      -Michael Harris.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}
